import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import FormValidator from "../../../../services/FormValidator";
import CrossWhiteIcon from "../../../../assets/icons/cross_icon_white.svg";
import CustomInput from "../../../components/Input";
import SaveIcon from "../../../../assets/icons/save.svg";
import { getAccessToken } from "../../../../services/browserStorage";
import { putDashboardWebApi } from "../../../../webApis/apiResource";
import {
  enableFormDirtiness,
  webApiCheck,
} from "../../../../services/commonMethods";
import {
  errorToast,
  successToast,
} from "../../../../modules/actions/toast.action";
import { connect } from "react-redux";
import {
  checkShowModal,
  setFormDirtiness,
  resetFormDirtiness,
} from "../../../../modules/actions/cancelConfirm.action";

class EditCompanyAdmin extends Component {
  state = {
    companyAdminName: "",
    companyAdminEmail: "",
    companyAdminContactNumber: null,
  };

  rules = {
    companyAdminName: { required: true },
    companyAdminEmail: { required: true, email: true },
    companyAdminContactNumber: { required: true, number: true },
  };

  //Submits the form.
  submit = async () => {
    //Changing data as per api need.
    const data = {
      ...this.state,
    };

    //Success handler
    let onSuccess = this.props.onSuccess;
    let urlEndPoint = `/api/company/${data.companyId}/company/admin`;

    const response = await putDashboardWebApi(
      getAccessToken(),
      urlEndPoint,
      data
    );
    if (webApiCheck(response)) {
      this.props.successToast({
        message: "Admin updated successfully",
      });
      onSuccess();
    }
  };

  btns = [
    {
      type: "submit",
      btnProps: {
        text: [<img src={SaveIcon} alt="save" key={0} />, "Update"],
        className: "save-btn float-right",
        variant: "contained",
        method: this.submit,
      },
    },
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Cancel"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: this.props.onClose,
      },
    },
  ];

  componentDidMount() {
    this.setState({ ...this.props.data });
  }

  componentWillUnmount() {
    this.props.resetFormDirtiness();
  }

  handleChange = (ev) => {
    this.setState(
      { [ev.target.name]: ev.target.value },
      enableFormDirtiness.bind(this, this.props.onClose)
    );
  };

  render() {
    const { companyAdminName, companyAdminEmail, companyAdminContactNumber } =
      this.state;

    return (
      <Dialog classes={{ paper: "add-user-modal" }} open={true}>
        <DialogTitle className="dialog-title">
          <span>Edit {this.props.data.companyAdminName} </span>
          <img src={CrossWhiteIcon} alt="cross" onClick={this.props.onClose} />
        </DialogTitle>
        <FormValidator
          rules={this.rules}
          data={this.state}
          btns={this.btns}
          errorToast={this.props.errorToast}
        >
          <DialogContent className="dialog-content">
            <Grid container>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: companyAdminName,
                    onChange: this.handleChange,
                    required: true,
                    label: "Name",
                    name: "companyAdminName",
                    type: "text",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: companyAdminEmail,
                    onChange: this.handleChange,
                    required: true,
                    label: "Email",
                    name: "companyAdminEmail",
                    type: "Email",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: companyAdminContactNumber,
                    onChange: this.handleChange,
                    required: true,
                    label: "Contact Number",
                    name: "companyAdminContactNumber",
                    type: "text",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
        </FormValidator>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  formDirtBool: state.cancelConfirmReducer.formDirtBool,
});

const mapDispatchToProps = (dispatch) => ({
  successToast: (obj) => dispatch(successToast(obj)),
  checkShowModal: (method) => dispatch(checkShowModal(method)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  setFormDirtiness: () => dispatch(setFormDirtiness()),
  resetFormDirtiness: () => dispatch(resetFormDirtiness()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyAdmin);
