/* eslint-disable no-extend-native */
//Returns date in YY-MM-DD format as it is required by input[type="date"].
Date.prototype.getInputDateFormat = function () {
  let month =
    this.getMonth() + 1 < 10 ? `0${this.getMonth() + 1}` : this.getMonth() + 1;
  let date = this.getDate() < 10 ? `0${this.getDate()}` : this.getDate();

  return this.getFullYear() + "-" + month.toString() + "-" + date;
};

Array.prototype.isEquals = function (arr2) {
  return JSON.stringify(this) === JSON.stringify(arr2);
};

//Convert array of json into comma separated string.
Array.prototype.getCommaSeperatedString = function (field) {
  let str = "";
  this.map(
    (element, index) => (str += (index > 0 ? ", " : "") + element[field])
  );
  return str;
};

//Find index of a key in an array of json
Array.prototype.findIndexOfKey = function (key, val) {
  let index = -1;
  this.forEach(function (item, i) {
    if (item[key] === val) {
      index = i;
    }
  });
  return index;
};

//Returns an element from last of the array.
Array.prototype.getElementFromLast = function (index) {
  let element = this[this.length - index];
  if (!element) {
    return;
  }
  return element;
};
