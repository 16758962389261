import { NAVBAR_BRAND_ROUTES } from "./index";

export const getActiveRoute = (title, path) => {
  let flag = false;
  NAVBAR_BRAND_ROUTES[title].forEach((route) => {
    if (path.search(route) === 0) {
      flag = true;
    }
  });
  return flag;
};
