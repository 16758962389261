import { PUT_PAYMENT_GATEWAYS_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { postDashboardWebApi } from "../../webApis/apiResource";

export const requestPaymentGatewaysList = (urlEndPoint, companyId = null) => {
  return async (dispatch) => {
    const payload = { companyId };

    const response = await postDashboardWebApi(
      getAccessToken(),
      urlEndPoint,
      payload
    );
    if (webApiCheck(response)) {
      dispatch(putPaymentGatewaysList(response.data.data));
    }
  };
};

const putPaymentGatewaysList = (data) => {
  return {
    type: PUT_PAYMENT_GATEWAYS_LIST,
    payload: data,
  };
};
