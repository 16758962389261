import { Card, CardContent, Grid } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import CustomButton from "../Button";
import PlusIcon from "../../../assets/icons/plus.svg";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

function Container(props) {
  const { title, showBtns, btnText, btnMethod, children, history } = props;
  return (
    <Grid container alignItems="flex-end">
      <Grid lg={12} className="m-0">
        <Breadcrumb />
      </Grid>
      <Grid item lg={showBtns ? 5 : 12}>
        <label className="container-title">{title}</label>
      </Grid>
      {showBtns ? (
        <Grid item lg={7} className="text-right">
          <CustomButton
            type="button"
            btnProps={{
              variant: "contained",
              onClick: btnMethod,
              text: [<img src={PlusIcon} alt="plus" key={0} />, btnText],
              className: "add-button",
            }}
          />
        </Grid>
      ) : (
        ""
      )}

      <Grid item lg={12}>
        <Card
          raised
          classes={{
            root: "container-card",
          }}
        >
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withRouter(Container);
