import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DEFAULT_SUCCESS_MESSAGE } from "../constants";
import CustomSnackBar from "../pages/components/Snackbar";
import CancelConfirm from "../pages/components/Modals/CancelConfirm/CancelConfirm";
import { objectHasKeysCheck } from "../services/commonMethods";
import { resetShowModal } from "../modules/actions/cancelConfirm.action";

class AppContainer extends Component {
  componentWillReceiveProps(nextProps) {
    let redirectPath = "";
    if (
      nextProps.successToastObj !== this.props.successToastObj &&
      objectHasKeysCheck(nextProps.successToastObj) &&
      (redirectPath = nextProps.successToastObj.path)
    ) {
      this.props.history.push(redirectPath);
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
        {objectHasKeysCheck(this.props.successToastObj) ? (
          <CustomSnackBar
            color="primary"
            show={objectHasKeysCheck(this.props.successToastObj)}
            message={
              objectHasKeysCheck(this.props.successToastObj)
                ? this.props.successToastObj.message
                : DEFAULT_SUCCESS_MESSAGE
            }
          />
        ) : (
          ""
        )}
        <CancelConfirm
          open={this.props.showCancelConfirmModal}
          onClose={() => this.props.resetShowModal()}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  successToastObj: state.toastReducer.successToastObj,
  showCancelConfirmModal: state.cancelConfirmReducer.showModal,
});

const mapDispatchToProps = (dispatch) => ({
  resetShowModal: () => dispatch(resetShowModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppContainer));
