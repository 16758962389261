import {
  SET_FORM_DIRTINESS,
  RESET_FORM_DIRTINESS,
  CHECK_SHOW_MODAL,
  RESET_SHOW_MODAL,
  RESET_CANCEL_CONFIRM,
} from "../../constants/action-constants";

const initialState = {
  formDirtBool: false,
  redirectMethod: () => {},
  showModal: false,
};

const cancelConfirmReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DIRTINESS:
      return { ...state, formDirtBool: true };
    case RESET_FORM_DIRTINESS:
      return { ...state, formDirtBool: false };
    case CHECK_SHOW_MODAL:
      return {
        ...state,
        showModal: state.formDirtBool,
        redirectMethod: action.payload,
      };
    case RESET_SHOW_MODAL:
      return { ...state, showModal: false };
    case RESET_CANCEL_CONFIRM:
      return initialState;
    default:
      return state;
  }
};

export default cancelConfirmReducer;
