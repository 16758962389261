import { PUT_PAYMENT_GATEWAYS_LIST } from "../../constants/action-constants";

//Using hardcoded data as api is not available yet.
const initialState = {
  list: [],
};

const PaymentGatewaysListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_PAYMENT_GATEWAYS_LIST:
      return { list: action.payload };
    default:
      return state;
  }
};

export default PaymentGatewaysListReducer;
