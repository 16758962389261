import React, { createContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { REACT_HISTORY_ACTIONS } from "../constants";
import { APP_ROUTES } from "../constants/appRoutes";
import { getSessionStorage, setSessionStorage } from "./browserStorage";
export const HistoryContext = createContext();

//constants
const USER_LAST_ROUTE_LENGTH = "userLastLength";
export const USER_PATHS = "userVisitedPaths";

//keep record of paths, user has visited
export default function UserHistory({ children }) {
  const location = useLocation();
  const history = useHistory();
  let userPaths = getSessionStorage(USER_PATHS);

  //check if user has refreshed the page.
  const isPageRefreshed =
    +getSessionStorage(USER_LAST_ROUTE_LENGTH) === history.length &&
    history.action === REACT_HISTORY_ACTIONS.POP &&
    userPaths &&
    userPaths.getElementFromLast(1) === location.pathname;

  //whenever location changes, set new fromRoute & toRoute
  useEffect(() => {
    let userPaths = [];
    const previousHistoryLength = +getSessionStorage(USER_LAST_ROUTE_LENGTH);
    let currentHistoryLength = history.length;
    let currentLocationPathname = location.pathname;
    //Do not allow home route to get stored in user history.
    currentLocationPathname =
      currentLocationPathname === APP_ROUTES.HOME
        ? APP_ROUTES.LIST_COMPANIES
        : currentLocationPathname;

    //User is visiting app for the first time or visting after surfing any other website on same tab.
    let userIsVisitingFirstTime = [
      previousHistoryLength,
      previousHistoryLength - 1,
      previousHistoryLength + 1,
      2,
    ].every(function (ele) {
      return currentHistoryLength !== ele;
    });

    userPaths = userIsVisitingFirstTime
      ? [...userPaths, currentLocationPathname]
      : getSessionStorage(USER_PATHS) || [];

    //prevent duplicate routes
    if (
      userPaths.getElementFromLast(1) === currentLocationPathname &&
      !userIsVisitingFirstTime
    ) {
      return;
    }

    //check if user is on new route.
    const userIsVisitingNewLink =
      history.action === REACT_HISTORY_ACTIONS.PUSH ||
      currentHistoryLength === 2 ||
      (userPaths.getElementFromLast(1) === APP_ROUTES.LOGIN &&
        currentLocationPathname === APP_ROUTES.LIST_COMPANIES);

    //check if user is going back
    const userIsPoppingRoutes =
      !isPageRefreshed &&
      history.action === REACT_HISTORY_ACTIONS.POP &&
      currentLocationPathname === userPaths.getElementFromLast(2);

    //If user is visiting any link, push that path to the array.
    // History length is 2 when users visits the website for the first time
    if (userIsVisitingNewLink) {
      userPaths = [...userPaths, currentLocationPathname];
    } else if (userIsPoppingRoutes) {
      //if user is going back to the previous paths, remove that path from the array
      userPaths.pop();
    }

    //Using session storage to allow usage of app on multiple tabs.
    setSessionStorage(USER_LAST_ROUTE_LENGTH, currentHistoryLength);
    setSessionStorage(USER_PATHS, JSON.stringify(userPaths));
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
}
