import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ForgotPassword from "../pages/containers/ForgotPassword";
import Login from "../pages/containers/Login";
import ResetPassword from "../pages/containers/ResetPassword";
import AppContainer from "./AppContainer";
import VendifyLogo from "../assets/icons/vendify_logo.svg";

class UnAuthRoutes extends Component {
  render() {
    return (
      <Router>
        <AppContainer>
          <div className="vendify-logo">
            <img src={VendifyLogo} alt="vendify" className="vendify-logo" />
          </div>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Redirect from="/" to="/login" />
          </Switch>
        </AppContainer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UnAuthRoutes);
