import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import React from "react";
import EditIcon from "../../../../assets/icons/edit_white.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";

export default function ActionColumn(props) {
  const { editMethod, viewMethod, approveMethod, disableButton } = props;
  return (
    <TableCell>
      {editMethod && (
        <Tooltip title="Edit" placement="top">
          <IconButton
            aria-label="Edit"
            className="action-btn license-btn edit"
            onClick={editMethod}
          >
            <img src={EditIcon} alt="edit" />
          </IconButton>
        </Tooltip>
      )}
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Tooltip title="View License" placement="top">
        <IconButton
          aria-label="View License"
          className="action-btn license-btn eye"
          onClick={viewMethod}
        >
          <img src={EyeIcon} alt="View License" />
        </IconButton>
      </Tooltip>
      &nbsp;&nbsp;&nbsp;&nbsp;
      {approveMethod && (
        <Tooltip title="Approve" placement="top">
          <IconButton
            aria-label="Approve"
            className="action-btn license-btn"
            onClick={disableButton ? () => {} : approveMethod}
          >
            <button
              className={
                disableButton
                  ? "approve-button disabled-btn"
                  : "approve-button enabled-btn"
              }
            >
              Approve
            </button>
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
