import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "../../components/Input";
import { Card, CardContent, InputAdornment } from "@material-ui/core";
import bottomBar from "../../../assets/images/bottom_image.svg";
import tick from "../../../assets/icons/tick.svg";
import FormValidator from "../../../services/FormValidator";
import MailIcon from "../../../assets/icons/material-mail.svg";
import { postAuthApi } from "../../../webApis/apiResource";
import { webApiCheck } from "../../../services/commonMethods";
import { successToast } from "../../../modules/actions/toast.action";
import { ReactComponent as BackKeyIcon } from "../../../assets/icons/back_arrow.svg";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };

    this.rules = {
      email: { required: true, email: true },
    };

    this.btns = [
      {
        type: "submit",
        btnProps: {
          variant: "contained",
          text: "Submit",
          className: "submit-button",
          method: this.submit,
        },
      },
    ];
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  submit = async () => {
    const response = await postAuthApi("/api/auth/forgotPassword", {
      email: this.state.email,
    });
    if (webApiCheck(response)) {
      this.props.successToast({
        message: "Email sent successfully",
        path: "/login",
      });
    }
  };

  render() {
    return (
      <div className="unauth-content forgot-password">
        <Card className="login-card">
          <div className="left-section">
            <div className="left-section-content">
              <h1>Welcome to Vendify</h1>
              <p>Do not panic we will help you to recover your password</p>
              <ul>
                <li>
                  <img src={tick} alt="tick" /> Enter your registered Email
                </li>
                <li>
                  <img src={tick} alt="tick" /> Link has been sent to your
                  registered mobile number
                </li>
                <li>
                  <img src={tick} alt="tick" /> Reset your password
                </li>
              </ul>
            </div>
            <img src={bottomBar} alt="bottom-bar" className="bottom-bar" />
          </div>
          <div className="right-section">
            <CardContent className="form-sec">
              <FormValidator
                rules={this.rules}
                data={this.state}
                btns={this.btns}
              >
                <div className="card-title">Forgot password</div>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    className: "login-input",
                  }}
                  inputProps={{
                    value: this.state.email,
                    onChange: this.handleChange,
                    placeholder: "Email",
                    name: "email",
                    required: true,
                    type: "email",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <i>
                            <img
                              src={MailIcon}
                              alt="email"
                              className="email-icon"
                            />
                          </i>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <div
                  className="forgot-password_redirect-login"
                  onClick={() => this.props.history.push("/login")}
                >
                  <i>
                    <BackKeyIcon />
                  </i>
                  <span>Back To Login</span>
                </div>
              </FormValidator>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  successToast: (obj) => dispatch(successToast(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
