import { PUT_COUNTRIES_LIST } from "../../constants/action-constants";

const initialState = {
  list: [],
};

const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_COUNTRIES_LIST:
      return { list: action.payload.data };
    default:
      return state;
  }
};

export default companyListReducer;
