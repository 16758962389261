import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Container from "../../components/Container";
import Delete from "../../components/Modals/Delete";
import ViewButtons from "../../components/ViewButtons";
import {
  checkIfJSONhasNoNullValues,
  checkUserHasPermission,
  getComponentTexts,
  userIsCompanyAdmin,
  USER_ROLE_IDS,
  webApiCheck,
} from "../../../services/commonMethods";
import { getDateAndTime } from "../../../services/commonMethods";
import { requestCompaniesList } from "../../../modules/actions/companyList.action";
import { PERMISSIONS, VENDIFY_BOARD_NAMES } from "../../../constants";
import { successToast } from "../../../modules/actions/toast.action";
import {
  requestCompany,
  resetCompany,
} from "../../../modules/actions/viewCompany.action";
import { putDashboardWebApi } from "../../../webApis/apiResource";
import { getAccessToken } from "../../../services/browserStorage";
import ViewLicense from "../LicenseManagement/ViewLicense";
import ConfirmLicenseModal from "../LicenseManagement/ConfirmLicenseModal/ConfirmLicenseModal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import AddIcon from "../../../assets/icons/add.svg";
import { APP_ROUTES } from "../../../constants/appRoutes";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { manualBreadcrumbMapping } from "../../../constants/breadcrumbMapping";
import { DEFAULT_VALUES } from "../../../constants";
import { BootstrapTooltip } from "../../components/TopNavbar";
import DevicePreview from "../../components/DevicePreview/DevicePreview";
class ViewCompany extends Component {
  state = {
    showDeleteModal: false,
    showViewModal: false,
    showApproveModal: false,
  };

  componentDidMount() {
    this.props.requestCompany(`/api/company/${this.props.match.params.id}`);
  }

  componentWillUnmount() {
    this.props.resetCompany();
  }

  onDeleteSuccess = () => {
    this.setState({
      showDeleteModal: false,
    });

    //Re-render the companies list.
    this.props.requestCompaniesList("/api/company");
    this.props.history.push("/companies");
  };

  approveLicenseSubmitHandler = (id) => {
    putDashboardWebApi(getAccessToken(), `/api/license/approval/${id}`).then(
      (response) => {
        if (webApiCheck(response)) {
          this.props.successToast({
            message: "License approved successfully",
          });

          this.props.requestCompany(
            `/api/company/${this.props.match.params.id}`
          );
          this.setState({ showApproveModal: false });
        }
      }
    );
  };

  viewScreenApproveMethod = (company) => {
    this.setState({ showApproveModal: true });
  };

  render() {
    let {
      companyId,
      companyAdminContactNumber,
      type,
      companyAdminName,
      companyAdminEmail,
      name,
      url,
      operationalCountry,
      validFrom,
      validTo,
      paymentGateways,
      address,
      accountDetail,
      integrationAdmins,
      supportAdmin,
      licenseVO,
      boards,
      createdOn,
    } = this.props.companyData;

    //Breadcrumb links to be shown.
    const breadcrumbData = manualBreadcrumbMapping.apply(this, [
      APP_ROUTES.VIEW_COMPANY,
    ]);

    return (
      name && (
        <div>
          <Breadcrumb data={breadcrumbData} />
          <Container title={"View Company"}>
            <Grid container>
              <Grid item lg={6}>
                <div className="view-company_details">
                  <h6 className="block-separator-heading">Basic Details</h6>
                  <div>
                    <h6>Name</h6>
                    <span className="view-company_name start-with-capital ">
                      {name}
                    </span>
                  </div>
                  <div>
                    <h6>URL</h6>
                    <BootstrapTooltip
                      title={url}
                      placement="top"
                      className="tooltip-text"
                    >
                      <span className="text-ellipses">
                        <a
                          href={url}
                          target="_blank"
                          className={!url && "pointer-none"}
                        >
                          {url || DEFAULT_VALUES.DOUBLE_DASH}
                        </a>
                      </span>
                    </BootstrapTooltip>
                  </div>
                  {type?.name && (
                    <div>
                      <h6>Company Type</h6>
                      <span>{type.displayName}</span>
                    </div>
                  )}
                  <div>
                    <h6>Operational Country</h6>
                    <span>{operationalCountry}</span>
                  </div>
                  <div>
                    <h6>Subscription Start</h6>
                    <span>{validFrom && getDateAndTime(validFrom)}</span>
                  </div>
                  <div>
                    <h6>Subscription End</h6>
                    <span>{validTo && getDateAndTime(validTo)}</span>
                  </div>
                  {paymentGateways.length && (
                    <div>
                      <h6>Payment Gateway</h6>
                      <span>
                        {
                          <img
                            src={paymentGateways[0].image}
                            alt={paymentGateways[0].name}
                            className="w-25"
                          />
                        }
                      </span>
                    </div>
                  )}
                  <div>
                    <h6>Integration Admins</h6>
                    <div className="view-company__integration-admins">
                      {integrationAdmins.map((admin) => (
                        <div className="integration-admins--admin">
                          <Accordion className="custom-accordian">
                            <AccordionSummary
                              className="header-accordian"
                              expandIcon={
                                <img src={AddIcon} alt="downward-arrow" />
                              }
                              classes={{
                                root: "filters-accordian-summary",
                                expandIcon: "filters-expand-icon",
                              }}
                            >
                              <label className="collapsible-navbar-title">
                                <h6>{admin.name}</h6>
                              </label>
                            </AccordionSummary>
                            <AccordionDetails className="accordian-details">
                              <p>
                                <h6>Mobile</h6>{" "}
                                <span>
                                  <a href={`tel:${admin.contactNumber}`}>
                                    {admin.contactNumber}
                                  </a>
                                </span>
                              </p>
                              <p>
                                <h6>Email</h6>
                                <span>
                                  <a href={`mailto:${admin.email}`}>
                                    {admin.email}
                                  </a>
                                </span>
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h6>Support Admin</h6>
                    <div>
                      {supportAdmin ? (
                        <div className="view-company__support-admin">
                          <h6>{supportAdmin.name}</h6>
                          <a href={`tel:${supportAdmin.contactNumber}`}>
                            {supportAdmin.contactNumber}
                          </a>
                          <a href={`mailto:${supportAdmin.email}`}>
                            {supportAdmin.email}
                          </a>
                        </div>
                      ) : (
                        DEFAULT_VALUES.DOUBLE_DASH
                      )}
                    </div>
                  </div>
                  {boards.map((ele, index) => {
                    return (
                      <div>
                        <h6>Board {index + 1} & quantity</h6>
                        <span>
                          {VENDIFY_BOARD_NAMES[ele.boardType.name]} -{" "}
                          {ele.numberOfBoards}
                        </span>
                      </div>
                    );
                  })}
                  <div>
                    <h6>On-boarding Date</h6>
                    <span>{createdOn && getDateAndTime(createdOn)}</span>
                  </div>
                  <h6 className="block-separator-heading">
                    Company Admin Details
                  </h6>
                  <div>
                    <h6>Name</h6>
                    <span style={{ textTransform: "capitalize" }}>
                      <Link to={`/company-admin/${companyId}`}>
                        {companyAdminName}
                      </Link>
                    </span>
                  </div>
                  <div>
                    <h6>Email</h6>
                    <span>{companyAdminEmail}</span>
                  </div>
                  <div>
                    <h6>Contact Number</h6>
                    <span>{companyAdminContactNumber}</span>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6}>
                <DevicePreview
                  primaryColor={this.props.companyData.primaryColor}
                  secondaryColor={this.props.companyData.secondaryColor}
                  companyLogo={this.props.companyData.logo}
                  name={this.props.companyData.name}
                />

                <div className="view-company_details">
                  {checkIfJSONhasNoNullValues(accountDetail) && (
                    <React.Fragment>
                      <h6 className="block-separator-heading">
                        Company Account Details
                      </h6>
                      {accountDetail.accountNumber && (
                        <div>
                          <h6>Account Number</h6>
                          <span>{accountDetail?.accountNumber}</span>
                        </div>
                      )}
                      {accountDetail.accountName && (
                        <div>
                          <h6>Account Name</h6>
                          <span>{accountDetail?.accountName}</span>
                        </div>
                      )}
                      {accountDetail.accountType && (
                        <div>
                          <h6>Account Type</h6>
                          <span>{accountDetail?.accountType?.displayName}</span>
                        </div>
                      )}
                      {accountDetail.bankName && (
                        <div>
                          <h6>Bank Name & Branch</h6>
                          <span>{accountDetail?.bankName}</span>
                        </div>
                      )}
                      {accountDetail.ifscCode && (
                        <div>
                          <h6>IFSC Code</h6>
                          <span>{accountDetail?.ifscCode}</span>
                        </div>
                      )}
                      {accountDetail.gstNumber && (
                        <div>
                          <h6>GST Number</h6>
                          <span>{accountDetail?.gstNumber}</span>
                        </div>
                      )}
                      {accountDetail.cancelCheque && (
                        <div>
                          <h6>Cancel Cheque</h6>
                          <span>
                            <img
                              src={accountDetail?.cancelCheque}
                              alt="Cancel cheque"
                              className="cancel-cheque"
                            />
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {address && (
                    <React.Fragment>
                      <h6 className="block-separator-heading">
                        Company's Registered Address
                      </h6>
                      <div>
                        <h6>Address</h6>
                        <span>
                          {address &&
                            `${address.lineOne}, ${address.lineTwo} ${address.city}, ${address.state}, ${address.zipCode}, ${address.countryName}`}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  {licenseVO && (
                    <>
                      <h6 className="block-separator-heading">
                        Licence Details
                      </h6>
                      <button
                        className="view-license-button"
                        onClick={() => this.setState({ showViewModal: true })}
                      >
                        View Licence Details
                      </button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          {checkUserHasPermission(this.props.me.role, [
            PERMISSIONS.UPDATE_AND_READ_COMPANY,
            PERMISSIONS.COMPANY_ALL_OPS,
            PERMISSIONS.UPDATE_MY_COMPANY,
          ]) && (
            <ViewButtons
              editMethod={() =>
                this.props.history.push(`/companies/edit-company/${companyId}`)
              }
              deleteMethod={
                this.props.me.role.id === USER_ROLE_IDS["company-admin"] ||
                this.props.me.role.id === USER_ROLE_IDS["integration-admin"]
                  ? false
                  : () => this.setState({ showDeleteModal: true })
              }
            />
          )}
          {this.state.showDeleteModal ? (
            <Delete
              open={this.state.showDeleteModal}
              onClose={() =>
                this.setState({
                  showDeleteModal: false,
                })
              }
              name={name}
              onSuccess={this.onDeleteSuccess}
              urlEndPoint={`/api/company/${this.props.match.params.id}`}
            />
          ) : (
            ""
          )}
          {this.state.showViewModal && (
            <ViewLicense
              data={this.props.companyData}
              approveMethodHandler={this.viewScreenApproveMethod}
              onClose={() => this.setState({ showViewModal: false })}
            />
          )}
          {this.state.showApproveModal && (
            <ConfirmLicenseModal
              onSuccess={this.approveLicenseSubmitHandler}
              onClose={() => this.setState({ showApproveModal: false })}
              data={this.props.companyData}
            />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.meReducer.data,
  companyData: state.viewCompanyReducer,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
  requestCompany: (urlEndPoint) => dispatch(requestCompany(urlEndPoint)),
  resetCompany: () => dispatch(resetCompany()),
  successToast: (obj) => dispatch(successToast(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCompany);
