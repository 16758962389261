import validator from "validator";
import { objectHasKeysCheck } from "./commonMethods";

export default function validation(data, rules) {
  let errors = {};
  Object.keys(data).forEach((field) => {
    if (objectHasKeysCheck(data[field]) && objectHasKeysCheck(rules[field])) {
      //For nested rules and data. Using recursion.
      let nestedErrors = validation(data[field], rules[field]);

      //adding errors returned from the recursion to the current errors field.
      errors = { ...errors, ...nestedErrors };
    } else if (rules.hasOwnProperty(field)) {
      let fielderrors = [];
      let currentRegularExpression = null;
      let val =
        typeof data[field] === "string" || data[field] === null
          ? data[field]
          : JSON.stringify(data[field]);
      if (val !== null && val !== undefined) {
        if (
          rules[field].required &&
          (validator.isEmpty(val) || val === "{}" || val === "[]")
        ) {
          fielderrors.push(`Value required`);
        }
        if (!validator.isEmpty(val)) {
          if (
            rules[field].isMobile &&
            parseInt(val).toString().length !== rules[field].exactlength
          ) {
            fielderrors.push(`Enter valid mobile number`);
          }
          if (
            rules[field].exactLength &&
            typeof val !== "number" &&
            val.length !== rules[field].exactLength
          ) {
            fielderrors.push(
              `Characters must be ${rules[field].exactLength}` + " in length"
            );
          }
          if (
            rules[field].minlength &&
            !validator.isLength(val, rules[field].minlength)
          ) {
            fielderrors.push(
              `Enter at least ${rules[field].minlength}` + " characters"
            );
          }
          if (rules[field].number && !validator.isNumeric(val)) {
            fielderrors.push("Enter only numbers");
          }
          if (
            rules[field].number &&
            validator.isNumeric(val) &&
            rules[field].minVal !== undefined &&
            parseInt(val) < rules[field].minVal
          ) {
            fielderrors.push(
              `Enter number greater than ${rules[field].minVal}`
            );
          }
          if (
            rules[field].number &&
            validator.isNumeric(val) &&
            rules[field].maxVal !== undefined &&
            parseInt(val) > rules[field].maxVal
          ) {
            fielderrors.push(
              `Enter number smaller than ${rules[field].maxVal}`
            );
          }
          if (rules[field].alpha && !validator.isAlpha(val)) {
            fielderrors.push("Enter only letters");
          }
          if (rules[field].email && !validator.isEmail(val)) {
            fielderrors.push("Enter a valid email address");
          }
          if (
            rules[field].pattern &&
            !validator.matches(val, rules[field].pattern)
          ) {
            fielderrors.push("Invalid File");
          }
          if (rules[field].url && !validator.isURL(val)) {
            fielderrors.push("Enter a valid url");
          }

          //Checks if the value has any Regular expression rule & then tests the value with it.
          if (
            (currentRegularExpression = rules[field].regExp) &&
            !currentRegularExpression.expression.test(val)
          ) {
            fielderrors.push(currentRegularExpression.message);
          }

          //Checks if current value needs to be equal with any other value.
          //If Yes, then get the comparison value using data variable.
          if (
            rules[field].shouldEqualTo &&
            !validator.equals(val, data[rules[field].shouldEqualTo])
          ) {
            //If any error message is passed, then show it. Otherwise show the default.
            fielderrors.push(
              rules[field].notEqualMessage ||
                `Value doesn't match with ${rules[field].shouldEqualTo}`
            );
          }
        }
      } else if (val === null && rules[field].required) {
        fielderrors.push(`Value required`);
      }
      if (fielderrors.length > 0) {
        errors[field] = fielderrors;
      }
    }
  });

  return errors;
}
