import { Grid } from "@material-ui/core";
import React from "react";
import defaultCompanyLogo from "../../../assets/icons/vendify_logo.svg";
import ProductSampleImage from "../../../assets/images/sample-product.jpg";
import CustomButton from "../Button";
import { ReactComponent as FeaturedStar } from "../../../assets/icons/product-featured-star.svg";
import { ReactComponent as CartIcon } from "../../../assets/icons/cart.svg";
import "./device_preview.scss";

export default function DevicePreview(props) {
  const { primaryColor, secondaryColor, companyLogo, name } = props;

  //Inline styling for DOM elements of this component.
  function getStyleSheets(id) {
    switch (id) {
      case "backgroundPrimary":
        return { background: primaryColor };
      case "backgroundSecondary":
        return { background: secondaryColor };
      case "borderPrimary":
        return { borderColor: primaryColor };
      case "borderSecondary":
        return { borderColor: secondaryColor };
      case "colorPrimary":
        return { color: primaryColor };
      case "colorSecondry":
        return { color: secondaryColor };
      case "colorBorderPrimary":
        return { color: primaryColor, borderColor: primaryColor };
      default:
        return {};
    }
  }
  return (
    <div className="device-preview">
      <div
        className="device-preview__header"
        style={getStyleSheets("backgroundPrimary")}
      >
        <Grid container className="pr-2 pl-2">
          <Grid md={2} item>
            <img
              src={companyLogo || defaultCompanyLogo}
              className="device-preview__header-logo-img"
              alt="Company logo to top of device app."
            />
          </Grid>
          <Grid md={8} item>
            <h1 className="text-ellipses">{name || "Company Name"}</h1>
          </Grid>
          <Grid md={2} item>
            <div className="device-preview__cart-icon">
              <i>
                <CartIcon />
              </i>
              <span style={getStyleSheets("backgroundSecondary")}>0</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="device-preview__subheader device-preview__product-box-button"
        style={getStyleSheets("backgroundSecondary")}
      >
        <marquee>* This is Some Scrolling text *</marquee>
      </div>
      <div className="device-preview__content">
        <div className="p-4">
          <ul>
            <li style={getStyleSheets("colorBorderPrimary")}>All</li>
            <li style={getStyleSheets("colorBorderPrimary")}>None</li>
          </ul>
          <Grid container>
            <Grid md={5} item>
              <div
                className="device-preview__product-box featured"
                style={getStyleSheets("borderPrimary")}
              >
                <div
                  className="device-preview__featured-product"
                  style={getStyleSheets("backgroundPrimary")}
                >
                  <i>
                    <FeaturedStar />
                  </i>
                </div>
                <div className="device-preview__product-box-image">
                  <img
                    src={ProductSampleImage}
                    alt="Product to be shown to customers"
                  />
                </div>
                <h2 style={getStyleSheets("colorPrimary")}>Product 1</h2>
                <p>Beverages </p>
                <div className="device-preview__product-box-footer">
                  <h2 style={getStyleSheets("colorPrimary")}>Rs 30</h2>
                  <CustomButton
                    type="button"
                    btnProps={{
                      className: "device-preview__product-box-button",
                      style: getStyleSheets("backgroundSecondary"),
                      text: [
                        "Add",
                        <span
                          style={getStyleSheets("backgroundPrimary")}
                          key={1}
                          className="plus-icon"
                        >
                          +
                        </span>,
                      ],
                      variant: "contained",
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid md={5} item>
              <div
                className="device-preview__product-box"
                style={getStyleSheets("borderPrimary")}
              >
                <div className="device-preview__product-box-image">
                  <img
                    src={ProductSampleImage}
                    alt="Product to be shown to customers"
                  />
                </div>
                <h2 style={getStyleSheets("colorPrimary")}>Product 1</h2>
                <p>Beverages </p>
                <div className="device-preview__product-box-footer">
                  <h2 style={getStyleSheets("colorPrimary")}>Rs 30</h2>
                  <CustomButton
                    type="button"
                    btnProps={{
                      className: "device-preview__product-box-button",
                      style: getStyleSheets("backgroundSecondary"),
                      text: [
                        "Add",
                        <span
                          style={getStyleSheets("backgroundPrimary")}
                          key={1}
                          className="plus-icon"
                        >
                          +
                        </span>,
                      ],
                      variant: "contained",
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
