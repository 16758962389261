import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { TABLES_REF } from "../../../constants";
import { LoaderContext } from "../../../gatekeeper";

export default function CustomTable(props) {
  const { tableHead, tableData, refr } = props;
  const [dataKeys, setDataKeys] = useState(refr);
  const context = useContext(LoaderContext);

  useEffect(() => {
    setDataKeys(TABLES_REF[refr]);
  }, [refr]);

  return (
    <Fragment>
      <Table className="custom-table">
        <TableHead className="custom-table-head">
          <TableRow>
            {tableHead.map((head, index) => {
              const { title, props } = head;
              return (
                <TableCell {...props} key={index}>
                  {title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length ? (
            tableData.map((data, index) => (
              <TableRow key={index}>
                {dataKeys[0] ? (
                  data[dataKeys[0]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[1] ? (
                  data[dataKeys[1]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[2] ? (
                  data[dataKeys[2]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[3] ? (
                  data[dataKeys[3]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[4] ? (
                  data[dataKeys[4]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[5] ? (
                  data[dataKeys[5]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[6] ? (
                  data[dataKeys[6]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[7] ? (
                  data[dataKeys[7]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
                {dataKeys[8] ? (
                  data[dataKeys[8]]
                ) : (
                  <TableCell style={{ display: "none" }} />
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              {dataKeys.length > 0 && !context ? (
                <TableCell colSpan={dataKeys.length} className="text-center">
                  No Data Available
                </TableCell>
              ) : (
                <TableCell
                  colSpan={dataKeys.length}
                  className="text-center load-table"
                >
                  <ul>
                    <li>Loading</li>
                    <li>.</li>
                    <li>.</li>
                    <li>.</li>
                  </ul>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  );
}
