import {
  COMPANY_WITHOUT_FRANCHISE_SERVICES,
  COMPANY_WITH_FRANCHISE_SERVICES,
  ENUMS,
  PERMISSIONS,
} from "../constants/index";
import APP_STRINGS from "../constants/languages/EN.json";

export const webApiCheck = (response) => {
  return (
    response &&
    (response.status === 200 ||
      response.status === 201 ||
      response.status === 204)
  );
};

export const objectHasKeysCheck = (obj) => {
  return obj && obj.constructor === Object && Object.keys(obj).length > 0;
};

export const getDateAndTime = (epoc, flag = 0) => {
  //flag=0, return date with time.
  //flag=1, return only date
  const date = new Date(epoc).toString().split(" ");
  const time = date[4].split(":");
  let format = "AM";
  if (parseInt(time[0]) > 12) {
    format = "PM";
    time[0] = time[0] - 12;
  }
  if (flag) {
    return `${date[1]} ${date[2]} ${date[3]}`;
  }
  return `${date[1]} ${date[2]} ${date[3]} ${time[0]}:${time[1]} ${format}`;
};

export const getTimeStamp = (time) => {
  return new Date(time).getTime();
};

export const checkRoute = (role, permissions) => {
  let flag = false;
  if (permissions.length === 0) {
    return true;
  }
  if (role) {
    role.permissionVOS.forEach((per) => {
      permissions.forEach((permission) => {
        if (per.authority === permission) {
          flag = true;
        }
      });
    });
  }
  return flag;
};

//Role ids for each user id
export const USER_ROLE_IDS = {
  "integration-partner": 2,
  "integration-admin": 3,
  "support-admin": 4,
  "company-admin": 5,
};

//Permissions require for add/edit using role id.
export const PERMISSIONS_FOR_USER_ROUTES = {
  [USER_ROLE_IDS["integration-partner"]]: [PERMISSIONS.INT_PARTNER_ALL_OPS],
  [USER_ROLE_IDS["integration-admin"]]: [PERMISSIONS.INT_ADMINS_ALL_OPS],
  [USER_ROLE_IDS["support-admin"]]: [PERMISSIONS.SUPPORT_ADMIN_ALL_OPS],
};

export const getUserConfig = (props) => {
  const {
    match: { path },
  } = props;
  switch (path) {
    case "/users":
    case "/user/:id":
      return {
        urlEndPoint: "/api/user/get/all",
        viewPath: "/user",
        title: "User List",
        addBtnText: "Add New User",
        afterDeleteRedirectPath: "/users",
        viewPageTitle: "User Overview",
        name: "User",
        disableRoleField: false,
        roleId: "",
        permissions: [
          PERMISSIONS.SUPPORT_ADMIN_ALL_OPS,
          PERMISSIONS.INT_PARTNER_ALL_OPS,
          PERMISSIONS.INT_ADMINS_ALL_OPS,
        ],
      };
    case "/integration-partners":
    case "/integration-partner/:id":
      return {
        urlEndPoint: "/api/user/get/all?roleId=2",
        viewPath: "/integration-partner",
        title: "Integration Partner List",
        addBtnText: "Add New Integration Partner",
        afterDeleteRedirectPath: "/integration-partners",
        viewPageTitle: "Integration Partner Overview",
        name: "Integration Partner",
        disableRoleField: true,
        roleId: USER_ROLE_IDS["integration-partner"],
        permissions:
          PERMISSIONS_FOR_USER_ROUTES[USER_ROLE_IDS["integration-partner"]],
      };
    case "/integration-admins":
    case "/integration-admin/:id":
      return {
        urlEndPoint: "/api/user/get/all?roleId=3",
        viewPath: "/integration-admin",
        title: "Integration Admin List",
        addBtnText: "Add New Integration Admin",
        afterDeleteRedirectPath: "/integration-admins",
        viewPageTitle: "Integration Admin Overview",
        name: "Integration Admin",
        disableRoleField: true,
        roleId: USER_ROLE_IDS["integration-admin"],
        permissions:
          PERMISSIONS_FOR_USER_ROUTES[USER_ROLE_IDS["integration-admin"]],
      };
    case "/support-admins":
    case "/support-admin/:id":
      return {
        urlEndPoint: "/api/user/get/all?roleId=4",
        viewPath: "/support-user",
        title: "Support Admin List",
        addBtnText: "Add New Support Admin",
        afterDeleteRedirectPath: "/support-users",
        viewPageTitle: "Support Admin Overview",
        name: "Support Admin",
        disableRoleField: true,
        roleId: USER_ROLE_IDS["support-admin"],
        permissions:
          PERMISSIONS_FOR_USER_ROUTES[USER_ROLE_IDS["support-admin"]],
      };
    default:
      return {};
  }
};

//Retreive a particular element with a condition
export const getParticularElementFromList = (data, field, value) => {
  return data.filter((ele) => ele[field] === value)[0];
};

//Checks if user has permission for something.
export const checkUserHasPermission = (role, permissions) => {
  let flag = false;
  if (role) {
    role.permissionVOS.forEach((per) => {
      if (permissions.indexOf(per.authority) > -1) {
        flag = true;
      }
    });
  }
  return flag;
};

//Returns services of a company on behalf of its franchises.
export const getCurrentCompanyServices = (hasFranchise = true) => {
  return !hasFranchise
    ? COMPANY_WITHOUT_FRANCHISE_SERVICES
    : COMPANY_WITH_FRANCHISE_SERVICES;
};

//Function that does nothing and prevent default behaviour
export const DUMMY_FUNCTION = (ev) => {
  ev.preventDefault();
};

export const checkIfJSONhasNoNullValues = (json) => {
  if (!json) {
    return false;
  }
  return Object.values(json).some((value) => value);
};

//Do not forget to bind this to this function.
export const enableFormDirtiness = function (callback) {
  this.props.setFormDirtiness();
  this.btns[1].btnProps.method = () =>
    this.props.checkShowModal(
      callback ? callback : () => this.props.history.goBack()
    );
};

//Returns object with a component texts to be shown.
export const getComponentTexts = function (location) {
  const splittedLocations = location.split(".");
  let answer = APP_STRINGS[splittedLocations[0]];

  for (let i = 1; i < splittedLocations.length; i++) {
    answer = answer[splittedLocations[i]];
  }

  return answer;
};

export const userIsCompanyAdmin = function (role_code = this.props.me.role.id) {
  return role_code === USER_ROLE_IDS["company-admin"];
};

export function convertImageToBase64(url, callback, outputFormat) {
  var canvas = document.createElement("CANVAS");
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL(outputFormat || "image/png");
    callback.call(this, dataURL);
    canvas = null;
  };
  img.src = url;
}
