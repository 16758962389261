import { PUT_CITIES_LIST } from "../../constants/action-constants";

//Using hardcoded data as api is not available yet.
const initialState = {
  list: [],
};

const citiesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_CITIES_LIST:
      return { list: action.payload };
    default:
      return state;
  }
};

export default citiesListReducer;
