import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { logOut } from "../modules/actions/login.action";
import LogOutWarning from "../pages/components/Modals/LogOutWarning";
import SideNavbar from "../pages/components/SideNavbar";
import TopNavbar from "../pages/components/TopNavbar";
import AppContainer from "./AppContainer";
import ROUTES from "./routes";
import IdleTimer from "react-idle-timer";
import { checkRoute } from "../services/commonMethods";
import UserHistory from "../services/UserHistory";

class AuthRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogOutWarning: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.idealTimeout = null;
  }

  _onAction(e) {
    localStorage.setItem("userActive", "true");
    // on action method
  }

  _onActive = (e) => {
    if (this.idealTimeout) {
      clearTimeout(this.idealTimeout);
    }
  };

  _onIdle = (e) => {
    localStorage.setItem("userActive", "false");
    this.setState({ showLogOutWarning: true });
    this.idealTimeout = setTimeout(() => {
      if (localStorage.getItem("userActive") === "false") {
        localStorage.removeItem("userActive");
        this.props.logout();
      }
    }, 1000 * 60 * 5);
  };

  render() {
    const { me } = this.props;
    return (
      <Router>
        <UserHistory>
          <AppContainer>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              events={[
                "keydown",
                "DOMMouseScroll",
                "mousedown",
                "touchstart",
                "touchmove",
                "MSPointerDown",
                "MSPointerMove",
              ]}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={1000 * 60 * 25}
            />
            <Grid container>
              <Grid item lg={12}>
                <TopNavbar />
              </Grid>
              <Grid item lg={2} className="side-navbar-container">
                <SideNavbar />
              </Grid>
              <Grid item lg={10} className="component-container">
                <div className="content">
                  <Switch>
                    {ROUTES.map((route, index) => {
                      return checkRoute(me.role, route.permissions) ? (
                        <Route
                          path={route.route}
                          component={route.component}
                          key={index}
                          exact
                        />
                      ) : (
                        ""
                      );
                    })}
                    {/*Redirects only when there is data in meReducer*/}
                    {me.role && <Redirect from="/" to="/companies" />}
                  </Switch>
                </div>
              </Grid>
            </Grid>
            {this.state.showLogOutWarning ? (
              <LogOutWarning
                open={this.state.showLogOutWarning}
                onClose={() => this.setState({ showLogOutWarning: false })}
              />
            ) : (
              ""
            )}
          </AppContainer>
        </UserHistory>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);
