import { Button } from "@material-ui/core";
import React from "react";

export default function CustomButton(props) {
  const { btnProps, type, ...restProps } = props;
  return (
    <Button
      type={type}
      {...btnProps}
      {...restProps}
      classes={{
        disabled: "disabled-btn",
      }}
    >
      {" "}
      {btnProps.text}
    </Button>
  );
}
