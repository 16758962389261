import { PUT_VENDING_MACHINE_BOARD_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestVendingMachineBoardsList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putVendingMachineBoardsList(response.data.data));
    }
  };
};

const putVendingMachineBoardsList = (data) => {
  return {
    type: PUT_VENDING_MACHINE_BOARD_LIST,
    payload: data,
  };
};
