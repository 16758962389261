import React from "react";
import CustomButton from "../Button";
import EditWhite from "../../../assets/icons/edit_white.svg";
import CrossWhite from "../../../assets/icons/cross_icon_white.svg";

export default function ViewButtons(props) {
  const { editMethod, deleteMethod } = props;

  return (
    <div className="d-flex ml-2 mt-4">
      <CustomButton
        type="submit"
        btnProps={{
          text: [<img src={EditWhite} alt="edit" key={0} />, "Edit"],
          variant: "contained",
          className: "view-button mr-3",
          onClick: editMethod,
        }}
      />
      {deleteMethod && (
        <CustomButton
          type="button"
          btnProps={{
            text: [<img src={CrossWhite} alt="delete" key={0} />, "Delete"],
            variant: "contained",
            className: "view-button delete-view-button",
            onClick: deleteMethod,
          }}
        />
      )}
    </div>
  );
}
