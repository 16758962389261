import { Cookies } from "react-cookie";
import { backButtonNotAllowedOn, BROWSER_STORAGE } from "../constants/index";
import { USER_PATHS } from "./UserHistory";
const cookies = new Cookies();

export const setAccessToken = (token) => {
  cookies.set(BROWSER_STORAGE.ACCESS_TOKEN, token, {
    path: "/",
    maxAge: BROWSER_STORAGE.COOKIE_EXPIRY_TIME,
  });
};

export const getAccessToken = () => cookies.get(BROWSER_STORAGE.ACCESS_TOKEN);

export const removeAccessToken = () => {
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN, { path: "/" });
};

export const getUserDetails = () => cookies.get(BROWSER_STORAGE.USERS_DETAILS);

export const removeOnlyAccessToken = () => {
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN, { path: "/" });
};

export const setSessionStorage = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => {
  let data;
  try {
    data = JSON.parse(sessionStorage.getItem(key));
  } catch {
    data = sessionStorage.getItem(key);
  }

  return data;
};

export const removeSessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  return sessionStorage.clear();
};

//checks if user has path history in the app.
export const checkIfUserHasPathHistory = () => {
  let userPaths = getSessionStorage(USER_PATHS);
  return !backButtonNotAllowedOn.includes(userPaths.getElementFromLast(2));
};
