import React, { Component } from "react";
import CustomButton from "../pages/components/Button";
import { getComponentTexts } from "./commonMethods";
import validation from "./validation";

export const ValidationContext = React.createContext();

class FormValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      formSubmitted: false,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  static getDerivedStateFromProps(props, state) {
    return {
      errors: validation(props.data, props.rules),
    };
  }

  get formValid() {
    return Object.keys(this.state.errors).length === 0;
  }

  handleClick = (btnType, submitFunc) => {
    if (btnType === "submit") {
      this.setState({ formSubmitted: true }, () => {
        if (this.formValid) {
          submitFunc();
        } else if (this.props.errorToast) {
          //Show toast if any error
          this.props.errorToast({
            data: {
              message: getComponentTexts("services.form_validator_error_toast"),
            },
          });
        }
      });
    } else {
      submitFunc();
    }
  };

  render() {
    const { btns, btnContainerClass } = this.props;
    return (
      <ValidationContext.Provider value={this.state}>
        <form onSubmit={this.handleFormSubmit} noValidate>
          <div>{this.props.children}</div>
          <div className={btnContainerClass}>
            {btns.map((btn, index) => {
              const {
                type,
                btnProps: { method, parentClass, ...restBtnprops },
              } = btn;
              return (
                <div className={parentClass} key={index}>
                  <CustomButton
                    btnProps={{ ...restBtnprops }}
                    onClick={() => this.handleClick(type, method)}
                    type={type}
                    disabled={
                      !this.formValid &&
                      this.state.formSubmitted &&
                      type === "submit"
                    }
                  />
                </div>
              );
            })}
          </div>
        </form>
      </ValidationContext.Provider>
    );
  }
}

export default FormValidator;
