export const BROWSER_STORAGE = {
  ACCESS_TOKEN: "access_token",
  USERS_DETAILS: "user_details",
  COOKIE_EXPIRY_TIME: 60 * 60 * 23,
};

//Regular expressions.
export const REG_EXPS = {
  PASSWORD_VALIDATION: {
    expression: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
    message: "Password is not valid",
  },
};

export const ENUMS = {
  COMPANY_LICENSE_STATUS: { PENDING: "PENDING", ACTIVE: "ACTIVE" },
  COMPANY_TYPE: {
    WITH_FRANCHISE: "WITH_FRANCHISE",
    WITHOUT_FRANCHISE: "WITHOUT_FRANCHISE",
  },
  DEVICE_APP_COLOR_CODES: {
    PRIMARY: "#f95a3f",
    SECONDARY: "#666f78",
  },
};

export const ENV_CONFIG = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  sentryApiKey: process.env.REACT_APP_SENTRY_KEY,
};

export const DEFAULT_ERROR_MESSAGE = "Something went wrong, Please try again.";
export const DEFAULT_SUCCESS_MESSAGE = "Action was successful!";

export const NAVBAR_BRAND_ROUTES = {
  Dashboard: ["/dashboard"],
  "Company Management": ["/companies", "/company-admins", "/licenses"],
  "Admin Management": ["/company-admins", "/company-admin"],
  "License Management": ["/licenses"],
  "User Management": [
    "/users",
    "/user",
    "/integration-partners",
    "/integration-partner",
    "/integration-admins",
    "/integration-admin",
    "/support-admins",
  ],
  "Integration Partner": ["/integration-partners", "/integration-partner"],
  "Integration Admin": ["/integration-admins", "/integration-admin"],
  "Support Admins": ["/support-admins", "/support-admin"],
};

export const TABLES_REF = {
  "user-table": ["name", "type", "email", "updatedOn", "action"],
  "company-table": [
    "name",
    "country",
    "companyAdmin",
    "lastUpdatedOn",
    "action",
  ],
  "company-admin-table": [
    "companyAdminName",
    "companyAdminEmail",
    "companyName",
    "lastUpdatedOn",
    "action",
  ],
  "license-table": [
    "companyName",
    "validFrom",
    "validTo",
    "onBoarding",
    "status",
    "action",
  ],
};

export const PERMISSIONS = {
  COMPANY_ALL_OPS: "COMPANY_ALL_OPS",
  UPDATE_AND_READ_COMPANY: "UPDATE_AND_READ_COMPANY",
  READ_COMPANY: "READ_COMPANY",
  INT_PARTNER_ALL_OPS: "INT_PARTNER_ALL_OPS",
  READ_INT_PARTNER: "READ_INT_PARTNER",
  INT_ADMINS_ALL_OPS: "INT_ADMINS_ALL_OPS",
  ASSIGN_PRIMARY_INT_ADMIN: "ASSIGN_PRIMARY_INT_ADMIN",
  READ_INT_ADMIN: "READ_INT_ADMIN",
  SUPPORT_ADMIN_ALL_OPS: "SUPPORT_ADMIN_ALL_OPS",
  READ_SUPPORT_ADMIN: "READ_SUPPORT_ADMIN",
  READ_SERVICES: "READ_SERVICES",
  READ_PAYMENTS: "READ_PAYMENTS",
  READ_PAYMENTS_ONLY_ASSIGNED: "READ_PAYMENTS_ONLY_ASSIGNED",
  APPROVE_AND_UPDATE_LICENSE: "APPROVE_AND_UPDATE_LICENSE",
  APPROVE_AND_UPDATE_LICENSE_ONLY_ASSIGNED:
    "APPROVE_AND_UPDATE_LICENSE_ONLY_ASSIGNED",
  READ_LICENSE: "READ_LICENSE",
  CHECK_LICENSE_ENFORCEMENT: "CHECK_LICENSE_ENFORCEMENT",
  READ_MY_COMPANY: "READ_MY_COMPANY",
  UPDATE_MY_COMPANY: "UPDATE_MY_COMPANY",
  READ_MY_DETAILS: "READ_MY_DETAILS",
  READ_COMPANY_LICENSE: "READ_COMPANY_LICENSE",
};

//Other constant values.
export const DEFAULT_COMPANY_VENDING_MACHINES = 0;
export const DEFAULT_MACHINE_BOARD_QUANITITY = 1;

export const SERVICENAME = {
  COMPANY_ADMIN_ACCOUNT: "Company Admin Account",
  ADD_COMPANY_SUPERVISORS: "Add Company Supervisors",
  PRODUCT_MANAGEMENT: "Product Management",
  ZONE_WISE_MANAGEMENT_OF_WAREHOUSE: "Zone Wise Management of Warehouse",
  VENDING_MACHINE_BOARDS_AS_ASKED_BY_COMPANY_ADMIN:
    "Vending Machine Boards as Asked by Company Admin",
  EASY_PAYMENT_TRANSACTIONS: "Easy Payment Transactions",
  SALES_ANALYTICS_ON_BASIS_OF_PRODUCTS_ZONES_WAREHOUSES_VENDING_MACHINES:
    "Sales Analytics on the basis of Products Zones, Warehouse & Vending Machines",
  HOSTING_TYPE_DEDICATED: "Hosting Type Dedicated",
  HOSTING_TYPE_SHARED: "Hosting Type Shared",
  ENABLE_FRANCHISE_OWNERS_ACCOUNT: "Enable Franchise Owners Account",
  ENABLE_COMPANY_PARTNERS_ACCOUNT: "Enable Company Partners Account",
  INVENTORY_PRODUCT_MANAGEMENT: "Inventory Product Management",
  INVENTORY_AND_STOCK_PRODUCT_MANAGEMENT:
    "Inventory & Stock Product Management",
  DELIVERY_MANAGEMENT_FROM_WAREHOUSE_TO_STORE_TO_VENDING_MACHINES:
    "Delivery Management from Warehouse to Store to Vending Machines",
  DELIVERY_MANAGEMENT_FROM_WAREHOUSE_TO_COMPANY_PARTNER_WAREHOUSE_TO_VENDING_MACHINES:
    "Delivery Management from Warehouse to Company Partner Warehouse to Vending Machines",
  DELIVERY_MANAGEMENT_FROM_WAREHOUSE_TO_VENDING_MACHINES:
    "Delivery Management from Warehouse to Vending Machines",
  DELIVERY_PARTNER_APP: "Delivery Partner App",
  ENABLE_WAREHOUSE_TO_WAREHOUSE_CONNECTIVITY:
    "Enable Warehouse to Warehouse Connectivity",
  ENABLE_OWN_PRODUCTS_PERMISSION_FOR_COMPANY_PARTNER:
    "Enable Own Product Permission for Company Partner",
  PAYMENT_FROM_VENDING_MACHINES_TO_FRANCHISE:
    "Payment from Vending Machines to Franchise",
  PAYMENT_FROM_VENDING_MACHINES_TO_COMPANY:
    "Payment from Vending Machines to Company",
  PAYMENT_FROM_VENDING_MACHINES_TO_COMPANY_TO_FRANCHISE:
    "Payment from Vending to Company to Franchise",
  ENABLE_PAYMENT_TRANSFER_FROM_COMPANY_TO_FRANCHISE:
    "Enable Payment Transfer from Company to Franchise",
  IN_BUILT_DISPLAY_VENDING_MACHINE_BOARDS:
    "In Built Display Vending Machine Boards",
  IN_BUILT_MODEM_VENDING_MACHINE_BOARDS:
    "In Built Modem Vending Machine Boards",
  IN_BUILT_BLUETOOTH_VENDING_MACHINE_BOARDS:
    "In Built Bluetooth Vending Machine Boards",
  IN_BUILT_WIFI_VENDING_MACHINE_BOARDS: "In Built Wifi Vending Machine Boards",
  IN_BUILT_ETHERNET_VENDING_MACHINE_BOARDS:
    "In Built Ethernet Vending Machine Boards",
  VENDING_MACHINE_BOARD_WORK_AS_A_MASTER:
    "Vending Machine Board Work as a Master",
  USING_THIRD_PARTY_BOARD: "Using Third Party Board",
  ENABLE_THIRD_PARTY_ADVERTISING: "Enable Third Party Advertising",
  AUTOMATIC_REFILL_PLAN_GENERATION: "Automatic Refill Plan Generation",
  ENABLE_NOTIFICATION_ON_MAIL_AND_SMS: "Enable Notification on Mail and SMS",
  ENABLE_SALES_ANALYTICS_REPORT_TO_MAIL: "Enable Sales Report to Mail",
};

//react-router history actions
export const REACT_HISTORY_ACTIONS = {
  POP: "POP",
  PUSH: "PUSH",
  REPLACE: "REPLACE",
};

//Routes on which back button is not allowed.
export const backButtonNotAllowedOn = ["undefined", undefined, "/login", ""];

//Vending machine boards name
export const VENDIFY_BOARD_NAMES = {
  PINE64: "Vendi-Pro (with Display)",
  ESP32: "Vendi-Mini (without Display)",
};

// Variables default values.
export const DEFAULT_VALUES = {
  DOUBLE_DASH: "--",
  NOT_APPLICABLE: "N/A",
};

//Services for the companies that has atleast one franchise.
export const COMPANY_WITH_FRANCHISE_SERVICES = [
  {
    title: "Company Admin Accounts",
    description:
      "Additional users to manage operations of your company through ERP.",
  },
  {
    title: "Product Management",
    description:
      "Create products and bundle them up in product categories, brands, size and packaging.",
  },
  {
    title: "Zone Management",
    description:
      "Divide your warehouses and franchises into zones for easy management.",
  },
  {
    title: "Warehouse Management",
    description: "Manage your warehouses with Vendify ERP at real time.",
  },
  {
    title: "Inventory Management",
    description: "Manage inventory of your warehouses.",
  },
  {
    title: "Product Partner Accounts",
    description:
      "Add Product Partners who helps you in providing more products for the consumers.",
  },
  {
    title: "Franchise Management",
    description:
      "Add Franchises to your company which helps in increasing your business.",
  },
  {
    title: "Franchise Admin Accounts",
    description: "Add Franchise admin to manage franchise side of operations.",
  },
  {
    title: "Store Management",
    description:
      "Franchises can add their stores from which products will be shipped to the vending machine.",
  },
  {
    title: "Stock Management",
    description: "Franchise Admins can manage stocks of their stores.",
  },
  {
    title: "Delivery Management",
    description:
      "Manage delivery of products from warehouse to store or from store to vending machine.",
  },
  {
    title: "Vending Machine Management",
    description: "Manage your vending machines and their refill plans",
  },
  {
    title: "Sales Analytics",
    description: "Take data driven decisions on the basis of sales.",
  },

  {
    title: "Always connected to your business",
    description: "Get important notifications on your email account.",
  },
  {
    title: "24/7 Support",
    description:
      "Availability of Vendify support team 24/7 in case you find any difficulty.",
  },
  {
    title: "Maintenance App",
    description:
      "Get real time information of your vending machines using a separate app.",
  },
  {
    title: "Consumer App",
    description:
      "A separate mobile app for end consumers who actually buys products from the vending machine.",
  },
];

//Services for the companies that has no franchise.
export const COMPANY_WITHOUT_FRANCHISE_SERVICES = [
  {
    title: "Company Admin Accounts",
    description:
      "Additional users to manage operations of your company through ERP.",
  },
  {
    title: "Product Management",
    description:
      "Create products and bundle them up in product categories, brands, size and packaging.",
  },
  {
    title: "Zone Management",
    description:
      "Divide your warehouses and franchises into zones for easy management.",
  },
  {
    title: "Warehouse Management",
    description: "Manage your warehouses with Vendify ERP at real time.",
  },
  {
    title: "Inventory Management",
    description: "Manage inventory of your warehouses.",
  },
  {
    title: "Product Partner Accounts",
    description:
      "Add Product Partners who helps you in providing more products for the consumers.",
  },
  {
    title: "Delivery Management",
    description:
      "Manage delivery of products from warehouse to store or from store to vending machine.",
  },
  {
    title: "Vending Machine Management",
    description: "Manage your vending machines and their refill plans",
  },
  {
    title: "Sales Analytics",
    description: "Take data driven decisions on the basis of sales.",
  },

  {
    title: "Always connected to your business",
    description: "Get important notifications on your email account.",
  },
  {
    title: "24/7 Support",
    description:
      "Availability of Vendify support team 24/7 in case you find any difficulty.",
  },
  {
    title: "Maintenance App",
    description:
      "Get real time information of your vending machines using a separate app.",
  },
  {
    title: "Consumer App",
    description:
      "A separate mobile app for end consumers who actually buys products from the vending machine.",
  },
];
