import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import loginReducer from "./login.reducer";
import toastReducer from "./toast.reducer";
import userListReducer from "./userList.reducer";
import rolesReducer from "./roles.reducer";
import viewUserReducer from "./viewUser.reducer";
import meReducer from "./me.reducer";
import servicesReducer from "./services.reducer";
import companyListReducer from "./companyList.reducer";
import countriesListReducer from "./countriesList.reducer";
import paymentGatewaysListReducer from "./paymentGatewaysList.reducer";
import paymentMethodsListReducer from "./paymentMethodsList.reducer";
import vendingMachineBoardList from "./vendingMachinesList.reducer";
import citiesListReducer from "./citiesList.reducer";
import statesListReducer from "./statesList.reducer";
import viewCompanyReducer from "./viewCompany.reducer";
import cancelConfirmReducer from "./cancelConfirm.reducer";
import { LOG_OUT } from "../../constants/action-constants";

const appReducer = combineReducers({
  notifications,
  loginReducer,
  toastReducer,
  userListReducer,
  rolesReducer,
  viewUserReducer,
  meReducer,
  servicesReducer,
  companyListReducer,
  countriesListReducer,
  paymentGatewaysListReducer,
  paymentMethodsListReducer,
  vendingMachineBoardList,
  citiesListReducer,
  statesListReducer,
  viewCompanyReducer,
  cancelConfirmReducer,
});

//Root reducer is used to revert back all reducers state to the initial state when user logs out.
const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    //All reducers return initial state when state not passed
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
