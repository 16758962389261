import React, { Component } from "react";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import CustomInput from "../../components/Input";
import SaveIcon from "../../../assets/icons/save.svg";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import FormValidator from "../../../services/FormValidator";
import { putDashboardWebApi } from "../../../webApis/apiResource";
import { getAccessToken } from "../../../services/browserStorage";
import {
  enableFormDirtiness,
  webApiCheck,
} from "../../../services/commonMethods";
import {
  checkShowModal,
  setFormDirtiness,
  resetFormDirtiness,
} from "../../../modules/actions/cancelConfirm.action";
import {
  errorToast,
  successToast,
} from "../../../modules/actions/toast.action";
import { connect } from "react-redux";

class EditLicense extends Component {
  state = {
    validTo: null,
    validFrom: null,
    licenseDescription: "",
  };

  rules = {
    licenseDescription: { required: true },
    validFrom: { required: true },
    validTo: { required: true },
  };

  componentDidMount() {
    const { validFrom, validTo, licenseVO, type } = this.props.data;

    this.setState({
      ...this.props.data,
      validFrom: new Date(validFrom).getInputDateFormat(),
      validTo: new Date(validTo).getInputDateFormat(),
      licenseDescription: licenseVO.description,
      type: type.name,
    });
  }

  componentWillUnmount() {
    this.props.resetFormDirtiness();
  }

  handleChange = (ev) => {
    this.setState(
      { [ev.target.name]: ev.target.value },
      enableFormDirtiness.bind(this, this.props.onClose)
    );
  };

  //Submits the form.
  submit = async () => {
    //Changing data as per api need.
    let integrationAdminIds = this.state.integrationAdmins.map((ele) => ele.id);
    const { accountDetail, validFrom, validTo, boards } = this.state;
    const data = {
      ...this.state,
      integrationAdminIds,
      accountDetail: {
        ...accountDetail,
        accountType: accountDetail.accountType?.name,
      },
      validFrom: new Date(validFrom).getTime(),
      validTo: new Date(validTo).getTime(),
      boards: boards.map((ele) => ({ ...ele, boardType: ele.boardType.name })),
    };

    //Success handler
    let onSuccess = this.props.onSuccess;
    let urlEndPoint = `/api/company/${data.companyId}`;
    let {
      companyAdminContactNumber,
      companyAdminEmail,
      companyAdminName,
      operationalCountry,
      operationalCountryCode,
      operationalCountryId,
      name,
      primaryIntegrationAdminId,
      url,
      supportAdminId,
    } = data;
    data.onBoardingCO = {
      companyAdminContactNumber,
      companyAdminEmail,
      companyAdminName,
      operationalCountry,
      operationalCountryCode,
      operationalCountryId,
      integrationAdminIds,
      name,
      primaryIntegrationAdminId,
      url,
      validFrom,
      validTo,
      supportAdminId,
    };
    let { onBoardingCO } = data;
    let nonOnBoardingCO = { ...data };
    delete nonOnBoardingCO["onBoardingCO"];

    const response = await putDashboardWebApi(getAccessToken(), urlEndPoint, {
      nonOnBoardingCO,
      onBoardingCO,
    });
    if (webApiCheck(response)) {
      this.props.successToast({
        message: "License updated successfully",
      });
      onSuccess();
    }
  };

  btns = [
    {
      type: "submit",
      btnProps: {
        text: [<img src={SaveIcon} alt="save" key={0} />, "Update"],
        className: "save-btn float-right",
        variant: "contained",
        method: this.submit,
      },
    },
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Cancel"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: this.props.onClose,
      },
    },
  ];

  render() {
    const { licenseDescription, validFrom, validTo } = this.state;
    return (
      <Dialog classes={{ paper: "add-user-modal" }} open={true}>
        <DialogTitle className="dialog-title">
          <span>Edit License</span>
          <img src={CrossWhiteIcon} alt="cross" onClick={this.props.onClose} />
        </DialogTitle>
        <FormValidator
          rules={this.rules}
          data={this.state}
          btns={this.btns}
          errorToast={this.props.errorToast}
        >
          <DialogContent className="dialog-content">
            <Grid container>
              <Grid item lg={12}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: licenseDescription,
                    onChange: this.handleChange,
                    required: true,
                    label: "License Description",
                    name: "licenseDescription",
                    type: "text",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: validFrom,
                    onChange: this.handleChange,
                    required: true,
                    label: "Valid From",
                    name: "validFrom",
                    type: "date",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: validTo,
                    onChange: this.handleChange,
                    required: true,
                    label: "Valid To",
                    name: "validTo",
                    type: "date",
                    inputProps: {
                      min: new Date().getInputDateFormat(),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
        </FormValidator>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  formDirtBool: state.cancelConfirmReducer.formDirtBool,
});

const mapDispatchToProps = (dispatch) => ({
  successToast: (obj) => dispatch(successToast(obj)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  checkShowModal: (method) => dispatch(checkShowModal(method)),
  setFormDirtiness: () => dispatch(setFormDirtiness()),
  resetFormDirtiness: () => dispatch(resetFormDirtiness()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLicense);
