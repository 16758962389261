import React, { Component } from "react";
import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import {
  checkUserHasPermission,
  getCurrentCompanyServices,
  getDateAndTime,
} from "../../../services/commonMethods";
import { ENUMS, PERMISSIONS } from "../../../constants";
import TickIcon from "../../../assets/icons/tick.svg";
import { connect } from "react-redux";

class ViewLicense extends Component {
  render() {
    const {
      name,
      validFrom,
      validTo,
      createdOn,
      lastUpdatedOn,
      licenseVO,
      type,
    } = this.props.data;

    //checks if license is not approved.
    let checkLicenseIsPending = () => {
      return licenseVO.status === ENUMS.COMPANY_LICENSE_STATUS.PENDING;
    };

    return (
      <div>
        <Dialog classes={{ paper: "view-license-modal" }} open={true}>
          <DialogTitle className="dialog-title">
            <span>License Agreement</span>
            <img
              src={CrossWhiteIcon}
              alt="cross"
              onClick={this.props.onClose}
            />
          </DialogTitle>
          <DialogContent className="dialog-content licence-view">
            <div className="table-view">
              <div>
                <h6>Organization Name : </h6>
                <span className="start-with-capital">
                  {name}{" "}
                  {checkLicenseIsPending() && (
                    <span className="license-not-approved">Not Approved</span>
                  )}{" "}
                </span>
              </div>
              <div>
                <h6>Subscription Valid : </h6>
                <span>
                  {getDateAndTime(validFrom, 1) +
                    " to " +
                    getDateAndTime(validTo, 1)}
                </span>
              </div>
              {licenseVO?.description && (
                <div>
                  <h6>License Description : </h6>
                  <span>{licenseVO.description}</span>
                </div>
              )}
              <div>
                <h6>On-boarding Date : </h6>
                <span>{getDateAndTime(createdOn, 1)}</span>
              </div>
              <div>
                <h6>Last Updated : </h6>
                <span>{getDateAndTime(lastUpdatedOn)}</span>
              </div>
              <div>
                <h6 style={{ transform: "translateY(-27px)" }}>
                  License Services :{" "}
                </h6>
                <ul className="company-license-services">
                  {getCurrentCompanyServices(
                    type?.name === ENUMS.COMPANY_TYPE.WITH_FRANCHISE
                  ).map((ele) => (
                    <li>
                      <span>{ele.title}</span>
                      {ele.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </DialogContent>
          <Divider />
          <div className="control-buttons">
            {checkLicenseIsPending() &&
              checkUserHasPermission(this.props.me.role, [
                PERMISSIONS.COMPANY_ALL_OPS,
                PERMISSIONS.APPROVE_AND_UPDATE_LICENSE,
                PERMISSIONS.APPROVE_AND_UPDATE_LICENSE_ONLY_ASSIGNED,
              ]) && (
                <button
                  className="float-right"
                  onClick={() =>
                    this.props.approveMethodHandler(this.props.data)
                  }
                >
                  {" "}
                  <img src={TickIcon} alt="Tick" /> Approve
                </button>
              )}
            {checkLicenseIsPending() && (
              <button
                className="float-right cancel-button"
                onClick={this.props.onClose}
              >
                <img src={CrossWhiteIcon} alt="Cross" />
                {this.props.me.role.name === "Company Admin"
                  ? "Close"
                  : "Cancel"}
              </button>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: state.meReducer.data,
});

export default connect(mapStateToProps)(ViewLicense);
