import React, { Component } from "react";
import Container from "../../../components/Container";
import CustomTable from "../../../components/Table";
import { connect } from "react-redux";
import { PERMISSIONS } from "../../../../constants";
import { requestCompaniesList } from "../../../../modules/actions/companyList.action";
import {
  checkUserHasPermission,
  userIsCompanyAdmin,
} from "../../../../services/commonMethods";
import { TableCell } from "@material-ui/core";
import RedirectToView from "../../../components/RedirectToView";
import DateColumn from "../../../components/DateColumn";
import ActionColumn from "../../../components/ActionColumn";
import EditCompanyAdmin from "./EditCompanyAdmin";

class CompanyAdminsList extends Component {
  state = {
    tableData: [],
    showEditModal: false,
    editModalObj: null,
    currentInstance: this,
  };

  componentDidMount() {
    //Get latest list of companies.
    this.props.requestCompaniesList("/api/company").then(() => {
      if (userIsCompanyAdmin.call(this)) {
        return this.props.history.push(
          `/company-admin/${this.props.companiesList[0].companyId}`
        );
      }
    });
  }

  tableHead = () => {
    let arr = [
      {
        title: "Name",
        props: {},
      },
      {
        title: "Email",
        props: {},
      },
      {
        title: "Organization Name",
        props: {},
      },
      {
        title: "Last Updated",
        props: {},
      },
      {
        title: "Action",
        props: {},
      },
    ];

    if (
      !checkUserHasPermission(this.props.me.role, [PERMISSIONS.COMPANY_ALL_OPS])
    ) {
      //If user doesn't have permission to Modify admin, then delete Action tab.
      arr.pop();
    }
    return arr;
  };

  static getDerivedStateFromProps(nextProps, state) {
    //Render table contents
    let temp = [];
    let redirectUserToView = (id) =>
      nextProps.history.push(`/company-admin/${id}`);

    nextProps.companiesList.forEach((company) => {
      const { companyAdminName, companyAdminEmail, name, companyId } = company;

      let json = {
        companyAdminName: (
          <RedirectToView
            redirectToView={() => {
              redirectUserToView(companyId);
            }}
          >
            <div className="text-ellipsis">{companyAdminName}</div>
          </RedirectToView>
        ),
        companyAdminEmail: (
          <TableCell>
            <div className="text-ellipsis">{companyAdminEmail}</div>
          </TableCell>
        ),
        companyName: (
          <TableCell>
            <div className="text-ellipsis">{name}</div>
          </TableCell>
        ),
        lastUpdatedOn: <DateColumn data={company} field="lastUpdatedOn" />,
        //Check if user has permission to edit
        action: checkUserHasPermission(nextProps.me.role, [
          PERMISSIONS.COMPANY_ALL_OPS,
        ]) ? (
          <ActionColumn
            editMethod={() =>
              state.currentInstance.setState({
                showEditModal: true,
                editModalObj: company,
              })
            }
          />
        ) : null,
      };
      temp.push(json);
    });

    //Update tableData in state.
    return { tableData: temp };
  }

  onSuccessEditHandler = () => {
    this.props.requestCompaniesList(`/api/company`);
    this.setState({ editModalObj: null, showEditModal: false });
  };

  render() {
    //Do not show admins list to company admin.
    if (userIsCompanyAdmin.call(this)) {
      return <></>;
    }

    return (
      <div>
        <Container title={"Admin List"} showBtns={false}>
          <CustomTable
            tableHead={this.tableHead()}
            tableData={this.state.tableData}
            refr="company-admin-table"
          />
        </Container>
        {this.state.showEditModal && (
          <EditCompanyAdmin
            data={this.state.editModalObj}
            onClose={() =>
              this.setState({ editModalObj: null, showEditModal: false })
            }
            onSuccess={this.onSuccessEditHandler}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesList: state.companyListReducer.list,
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminsList);
