import {
  SUCCESS_TOAST,
  ERROR_TOAST,
  RESET_ERROR_TOAST,
  RESET_SUCCESS_TOAST,
} from "../../constants/action-constants";

const initialState = {
  errorToastObj: {},
  successToastObj: {},
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_TOAST:
      return { successToastObj: action.payload, errorToastObj: {} };
    case ERROR_TOAST:
      return { successToastObj: {}, errorToastObj: action.payload };
    case RESET_ERROR_TOAST:
      return { ...state, errorToastObj: {} };
    case RESET_SUCCESS_TOAST:
      return { ...state, successToastObj: {} };
    default:
      return state;
  }
};

export default toastReducer;
