import {
  ERROR_TOAST,
  SUCCESS_TOAST,
  RESET_ERROR_TOAST,
  RESET_SUCCESS_TOAST,
} from "../../constants/action-constants";

export const errorToast = (obj) => {
  return {
    type: ERROR_TOAST,
    payload: obj,
  };
};

export const successToast = (obj) => {
  return {
    type: SUCCESS_TOAST,
    payload: obj,
  };
};

export const resetErrorToast = () => {
  return {
    type: RESET_ERROR_TOAST,
  };
};

export const resetSuccessToast = () => {
  return {
    type: RESET_SUCCESS_TOAST,
  };
};
