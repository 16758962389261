import { PUT_CITIES_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { postDashboardWebApi } from "../../webApis/apiResource";

export const requestCitiesList = (urlEndPoint, companyId) => {
  return async (dispatch) => {
    const response = await postDashboardWebApi(getAccessToken(), urlEndPoint, {
      companyId,
    });
    if (webApiCheck(response)) {
      dispatch(putCitiesList(response.data.data));
    }
  };
};

export const resetCitiesList = () => {
  return async (dispatch) => {
    dispatch(putCitiesList([]));
  };
};

const putCitiesList = (data) => {
  return {
    type: PUT_CITIES_LIST,
    payload: data,
  };
};
