import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import DeletePopIcon from "../../../assets/images/delete_popup_icon.svg";
import { getComponentTexts } from "../../../services/commonMethods";
import CustomButton from "../Button";

function EditModal(props) {
  const { open, onClose, alertMessage, alertHeading, onEditForm } = props;
  const modalStrings = getComponentTexts("components.modals.edit_modal");
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: "delete-modal" }}>
      <DialogTitle className="text-center">
        <img
          src={DeletePopIcon}
          alt="Edit-popup"
          className="delete-popup-icon"
        />
      </DialogTitle>
      <DialogContent className="text-center delete-dialog-content">
        <h3>{alertHeading || modalStrings.alert_heading}</h3>
        <span>{alertMessage}</span>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className="text-center mt-4">
            <CustomButton
              type="button"
              btnProps={{
                text: modalStrings.cancel_message,
                className: "cancel-btn mr-5",
                variant: "contained",
                onClick: onClose,
              }}
            />
            <CustomButton
              type="button"
              btnProps={{
                text: modalStrings.submit_message,
                className: "cancel-btn delete-btn",
                variant: "contained",
                onClick: onEditForm,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(EditModal);
