import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ROUTES from "../../../routeManagement/routes";
import DownArrow from "../../../assets/icons/down-arrow.svg";
import { getActiveRoute } from "../../../constants/mapping";
import { useSelector, useDispatch } from "react-redux";
import { checkRoute } from "../../../services/commonMethods";
import { checkShowModal } from "../../../modules/actions/cancelConfirm.action";

function SideNavbar(props) {
  const [routes, setRoutes] = useState([]);
  const me = useSelector((state) => state.meReducer.data);
  const dispatchActions = useDispatch();
  const formDirtBool = useSelector(
    (state) => state.cancelConfirmReducer.formDirtBool
  );

  useEffect(() => {
    setRoutes(
      ROUTES.filter((route) => {
        return route.sidebar && checkRoute(me.role, route.permissions);
      })
    );
  }, [me]);

  const handleClick = (route) => {
    if (formDirtBool) {
      dispatchActions(checkShowModal(() => props.history.push(route)));
    } else {
      //prevent extra rendering of same page
      if (route !== props.location.pathname) {
        props.history.push(route);
      }
    }
  };

  const formLink = (route) => {
    return (
      <Accordion
        key={route.name}
        classes={{
          root: "accordion-root-sidenavbar",
        }}
      >
        <AccordionSummary
          expandIcon={
            route.hasSubRoutes ? (
              <img src={DownArrow} alt={route.name} className="float-right" />
            ) : (
              ""
            )
          }
          classes={{
            root: `side-navbar-link ${
              getActiveRoute(route.name, props.location.pathname)
                ? "active-link"
                : "inactive-link"
            }`,
            expanded: "accordion-expanded-sidenavbar-link",
          }}
        >
          <div
            className="sidebar-anchor"
            onClick={() => handleClick(route.route)}
          >
            <img src={route.icon} alt={route.name} />
            &nbsp;&nbsp;
            <label style={{ width: "calc(100% - 60px)", float: "right" }}>
              {route.name}
            </label>
          </div>
        </AccordionSummary>
        {route.hasSubRoutes ? (
          <AccordionDetails
            style={{ display: "block", backgroundColor: "#1D354D" }}
          >
            {route.subRoutes.map((subRoute, subIndex) => {
              return checkRoute(me.role, subRoute.permissions) ? (
                <div
                  className={`side-navbar-link ${
                    getActiveRoute(subRoute.name, props.location.pathname)
                      ? "sub-active-link"
                      : "sub-inactive-link"
                  }`}
                  onClick={() => handleClick(subRoute.route)}
                  key={subIndex}
                >
                  <img
                    src={
                      getActiveRoute(subRoute.name, props.location.pathname)
                        ? subRoute.activeIcon
                        : subRoute.icon
                    }
                    alt={subRoute.name}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <label style={{ width: "calc(100% - 40px)", float: "right" }}>
                    {subRoute.name}
                  </label>
                </div>
              ) : (
                ""
              );
            })}
          </AccordionDetails>
        ) : (
          ""
        )}
      </Accordion>
    );
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      elevation={10}
      classes={{
        paperAnchorLeft: "side-navbar",
      }}
    >
      {routes.map((route) => formLink(route))}
    </Drawer>
  );
}

export default withRouter(SideNavbar);
