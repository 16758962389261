import { PUT_COUNTRIES_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { postDashboardWebApi } from "../../webApis/apiResource";

export const requestCountriesList = (urlEndPoint, companyId = null) => {
  return async (dispatch) => {
    const response = await postDashboardWebApi(getAccessToken(), urlEndPoint, {
      companyId,
    });
    if (webApiCheck(response)) {
      dispatch(putCountriesList(response.data));
    }
  };
};

const putCountriesList = (data) => {
  return {
    type: PUT_COUNTRIES_LIST,
    payload: data,
  };
};
