import React, { Component } from "react";
import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import CrossWhiteIcon from "../../../../assets/icons/cross_icon_white.svg";
import { getDateAndTime } from "../../../../services/commonMethods";
import TickIcon from "../../../../assets/icons/tick.svg";

class ConfirmLicenseModal extends Component {
  render() {
    const { name, validFrom, validTo, licenseVO } = this.props.data;

    return (
      <div>
        <Dialog classes={{ paper: "confirm-license-modal" }} open={true}>
          <DialogTitle className="dialog-title">
            <span>Approve License Agreement</span>
            <img
              src={CrossWhiteIcon}
              alt="cross"
              onClick={this.props.onClose}
            />
          </DialogTitle>
          <DialogContent className="dialog-content">
            <span>
              By clicking on the 'Confirm' button, you approve the license
              agreement between the{" "}
              <span className="highlighed-company-name">{name}</span> company
              and Vendify, valid from{" "}
              {getDateAndTime(validFrom, 1) +
                " to " +
                getDateAndTime(validTo, 1)}
              .
            </span>
          </DialogContent>
          <Divider />
          <div className="control-buttons">
            <button
              className="float-right"
              onClick={() => this.props.onSuccess(licenseVO.id)}
            >
              {" "}
              <img src={TickIcon} alt="Tick" /> Confirm
            </button>
            <button
              className="float-right cancel-button"
              onClick={this.props.onClose}
            >
              <img src={CrossWhiteIcon} alt="Cross" />
              Cancel
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmLicenseModal;
