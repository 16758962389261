import { LOGIN_SUCCESS, LOG_OUT } from "../../constants/action-constants";
import {
  removeAccessToken,
  setAccessToken,
  clearSessionStorage,
} from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { postAuthApi } from "../../webApis/apiResource";
import { requestMe } from "./me.action";

export const requestLogin = (urlEndPoint, payload) => {
  return async (dispatch) => {
    const response = await postAuthApi(urlEndPoint, payload);
    if (webApiCheck(response)) {
      setAccessToken(response.data.token);
      dispatch(loginSuccess());
      dispatch(requestMe("/api/user/me"));
    }
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const logOut = () => {
  removeAccessToken();
  clearSessionStorage();
  return {
    type: LOG_OUT,
  };
};
