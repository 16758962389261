import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "../../components/Input";
import { Card, CardContent, InputAdornment } from "@material-ui/core";
import bottomBar from "../../../assets/images/bottom_image.svg";
import tick from "../../../assets/icons/tick.svg";
import eyeVisible from "../../../assets/icons/eyeVisible.svg";
import eyeHide from "../../../assets/icons/eyeHide.svg";
import FormValidator from "../../../services/FormValidator";
import { requestLogin } from "../../../modules/actions/login.action";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isPasswordShown: false,
    };

    this.rules = {
      email: { required: true, email: true },
      password: { required: true },
    };

    this.btns = [
      {
        type: "submit",
        btnProps: {
          variant: "contained",
          text: "Submit",
          className: "submit-button",
          method: this.submit,
        },
      },
    ];
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  togglePassword = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  submit = async () => {
    this.props.requestLogin(`/api/auth/login`, {
      email: this.state.email,
      password: this.state.password,
    });
  };

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="unauth-content">
        <Card className="login-card">
          <div className="left-section">
            <div className="left-section-content">
              <h1>Welcome to Vendify</h1>
              <p>Login in to your account</p>
              <ul>
                <li>
                  <img src={tick} alt="tick" /> Secured agreements between
                  Vendify and Customers.
                </li>
                <li>
                  <img src={tick} alt="tick" /> See your services with Vendify
                </li>
                <li>
                  <img src={tick} alt="tick" /> Manage your company details
                </li>
              </ul>
            </div>
            <img src={bottomBar} alt="bottom-bar" className="bottom-bar" />
          </div>
          <div className="right-section">
            <CardContent className="form-sec">
              <FormValidator
                rules={this.rules}
                data={this.state}
                btns={this.btns}
              >
                <div className="card-title">Login</div>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    className: "login-input",
                  }}
                  inputProps={{
                    value: this.state.email,
                    onChange: this.handleChange,
                    placeholder: "Email",
                    name: "email",
                    required: true,
                    type: "email",
                  }}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: "login-input",
                  }}
                  inputProps={{
                    value: this.state.password,
                    onChange: this.handleChange,
                    placeholder: "Password",
                    name: "password",
                    required: true,
                    type: isPasswordShown ? "text" : "password",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={isPasswordShown ? eyeHide : eyeVisible}
                            alt="eye"
                            className="eye-icon"
                            onClick={this.togglePassword}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <div className="forgot-password-text">
                  <span
                    onClick={() => this.props.history.push("/forgot-password")}
                  >
                    {" "}
                    Forgot Password?
                  </span>
                </div>
              </FormValidator>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  requestLogin: (urlEndPoint, payload) =>
    dispatch(requestLogin(urlEndPoint, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
