import React from "react";
import { Component } from "react";
import { ValidationContext } from "./FormValidator";

class ValidationMessage extends Component {
  static contextType = ValidationContext;
  render() {
    return (
      <React.Fragment>
        {this.context &&
        this.context.errors &&
        this.context.errors[this.props.name] &&
        this.context.formSubmitted ? (
          this.context.errors[this.props.name].map((error, index) => (
            <p className="error-msg" key={index}>
              {error}
            </p>
          ))
        ) : (
          <p />
        )}
      </React.Fragment>
    );
  }
}

export default ValidationMessage;
