import {
  FETCH_USER_LIST,
  PUT_INTEGRATION_ADMIN_LIST,
  PUT_SUPPORT_ADMIN_LIST,
} from "../../constants/action-constants";

const initialState = {
  list: [],
  integrationAdminsList: [],
  supportAdminsList: [],
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_LIST:
      return { ...state, list: action.payload };
    case PUT_INTEGRATION_ADMIN_LIST:
      return { ...state, integrationAdminsList: action.payload };
    case PUT_SUPPORT_ADMIN_LIST:
      return { ...state, supportAdminsList: action.payload };
    default:
      return state;
  }
};

export default userListReducer;
