import React, { Component } from "react";
import { connect } from "react-redux";
import { TableCell } from "@material-ui/core";
import { requestCompaniesList } from "../../../modules/actions/companyList.action";
import {
  checkUserHasPermission,
  webApiCheck,
} from "../../../services/commonMethods";
import { ENUMS, PERMISSIONS } from "../../../constants";
import DateColumn from "../../components/DateColumn";
import RedirectToView from "../../components/RedirectToView";
import CustomTable from "../../components/Table";
import Container from "../../components/Container";
import ActionColumn from "./ActionColumn/ActionColumn";
import ViewLicense from "./ViewLicense";
import { putDashboardWebApi } from "../../../webApis/apiResource";
import { getAccessToken } from "../../../services/browserStorage";
import { successToast } from "../../../modules/actions/toast.action";
import ConfirmLicenseModal from "./ConfirmLicenseModal/ConfirmLicenseModal";
import EditLicense from "./EditLicense";

//Component list all the companies in a table & provides Add/Edit/Delete Company button.
class LicensesList extends Component {
  state = {
    tableData: [],
    currentInstance: this,
    showViewModal: false,
    viewModalObj: null,
    showApproveModal: false,
    approveModalObj: null,
    showEditModal: false,
    editModalObj: null,
  };

  redirectUserToCompany = ({ companyId }) => {
    this.props.history.push(`companies/${companyId}`);
  };

  componentDidMount() {
    //Get latest list of companies
    this.props.requestCompaniesList("/api/company");
  }

  getLicenseStatus(status) {
    switch (status) {
      case ENUMS.COMPANY_LICENSE_STATUS.PENDING:
        return <TableCell className="status-pending">Not Approved</TableCell>;
      case ENUMS.COMPANY_LICENSE_STATUS.ACTIVE:
        return <TableCell className="status-active">Approved</TableCell>;
      default:
        return <TableCell>{status}</TableCell>;
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let temp = [];
    let { currentInstance } = state;

    nextProps.companiesList.forEach((company) => {
      //IF company has license, only then show the license.
      if (company.licenseVO) {
        let json = {
          companyName: (
            <RedirectToView
              redirectToView={() =>
                currentInstance.redirectUserToCompany(company)
              }
            >
              {" "}
              <div className="text-ellipsis">{company.name}</div>
            </RedirectToView>
          ),
          validFrom: (
            <DateColumn showDateOnly={true} data={company} field="validFrom" />
          ),
          validTo: (
            <DateColumn showDateOnly={true} data={company} field="validTo" />
          ),
          status: currentInstance.getLicenseStatus(company.licenseVO?.status),
          //Check if user has permission to edit
          action: (
            <ActionColumn
              viewMethod={() =>
                currentInstance.setState({
                  showViewModal: true,
                  viewModalObj: company,
                })
              }
              disableButton={
                company.licenseVO?.status ===
                ENUMS.COMPANY_LICENSE_STATUS.ACTIVE
              }
              approveMethod={
                checkUserHasPermission(nextProps.me.role, [
                  PERMISSIONS.COMPANY_ALL_OPS,
                  PERMISSIONS.APPROVE_AND_UPDATE_LICENSE,
                  PERMISSIONS.APPROVE_AND_UPDATE_LICENSE_ONLY_ASSIGNED,
                ])
                  ? () =>
                      currentInstance.setState({
                        showApproveModal: true,
                        approveModalObj: company,
                      })
                  : false
              }
              editMethod={
                checkUserHasPermission(nextProps.me.role, [
                  PERMISSIONS.COMPANY_ALL_OPS,
                  PERMISSIONS.APPROVE_AND_UPDATE_LICENSE,
                  PERMISSIONS.APPROVE_AND_UPDATE_LICENSE_ONLY_ASSIGNED,
                ])
                  ? () =>
                      currentInstance.setState({
                        showEditModal: true,
                        editModalObj: company,
                      })
                  : false
              }
            />
          ),
        };
        temp.push(json);
      }
    });
    //Update state.
    return { tableData: temp };
  }

  tableHead = () => {
    let arr = [
      {
        title: "Company Name",
        props: {},
      },
      {
        title: "Valid From",
        props: {},
      },
      {
        title: "Valid To",
        props: {},
      },
      {
        title: "Status",
        props: {},
      },
      {
        title: "Action",
        props: {},
      },
    ];
    return arr;
  };

  approveLicenseSubmitHandler = (id) => {
    putDashboardWebApi(getAccessToken(), `/api/license/approval/${id}`).then(
      (response) => {
        if (webApiCheck(response)) {
          this.props.successToast({
            message: "License approved successfully",
          });
          //Get latest list of companies
          this.props.requestCompaniesList("/api/company");

          //Remove approve button from view license
          let { viewModalObj } = this.state;
          viewModalObj = viewModalObj
            ? {
                ...viewModalObj,
                licenseVO: {
                  ...viewModalObj.licenseVO,
                  status: ENUMS.COMPANY_LICENSE_STATUS.ACTIVE,
                },
              }
            : null;

          this.setState({
            viewModalObj,
            showApproveModal: false,
            approveModalObj: null,
          });
        }
      }
    );
  };

  viewScreenApproveMethod = (company) => {
    this.setState({ showApproveModal: true, approveModalObj: company });
  };

  onSuccessEditHandler = () => {
    //Get latest list of companies
    this.props.requestCompaniesList("/api/company");

    this.setState({ showEditModal: false, editModalObj: null });
  };

  render() {
    return (
      <div className="licenses-list">
        <Container title={"License List"}>
          <CustomTable
            tableHead={this.tableHead()}
            tableData={this.state.tableData}
            refr="license-table"
          />
        </Container>
        {this.state.showViewModal && (
          <ViewLicense
            data={this.state.viewModalObj}
            approveMethodHandler={this.viewScreenApproveMethod}
            onClose={() =>
              this.setState({ viewModalObj: null, showViewModal: false })
            }
          />
        )}
        {this.state.showApproveModal && (
          <ConfirmLicenseModal
            onSuccess={this.approveLicenseSubmitHandler}
            onClose={() =>
              this.setState({ showApproveModal: false, approveModalObj: null })
            }
            data={this.state.approveModalObj}
          />
        )}
        {this.state.showEditModal && (
          <EditLicense
            data={this.state.editModalObj}
            onSuccess={this.onSuccessEditHandler}
            onClose={() =>
              this.setState({ showEditModal: false, editModalObj: null })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesList: state.companyListReducer.list,
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
  successToast: (obj) => dispatch(successToast(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LicensesList);
