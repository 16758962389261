import { PUT_SERVICES_LIST } from "../../constants/action-constants";

const initialState = {
  list: [],
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_SERVICES_LIST:
      return { list: action.payload };
    default:
      return state;
  }
};

export default servicesReducer;
