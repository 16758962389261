import React, { Component } from "react";
import Container from "../../../components/Container";
import {
  requestCompanyData,
  resetCompanyData,
} from "../../../../modules/actions/companyList.action";
import { connect } from "react-redux";
import { Grid, Divider } from "@material-ui/core";
import { PERMISSIONS } from "../../../../constants";
import {
  checkUserHasPermission,
  getComponentTexts,
  userIsCompanyAdmin,
} from "../../../../services/commonMethods";
import ViewButtons from "../../../components/ViewButtons";
import EditCompanyAdmin from "./EditCompanyAdmin";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { APP_ROUTES } from "../../../../constants/appRoutes";
import { manualBreadcrumbMapping } from "../../../../constants/breadcrumbMapping";

class ViewCompanyAdmin extends Component {
  state = {
    showEditModal: false,
    editModalObj: null,
  };

  componentDidMount() {
    this.props.requestCompanyData(`/api/company/${this.props.match.params.id}`);
  }

  componentWillUnmount() {
    //Resets company data in reducer, whenever user exits this page.
    this.props.resetCompanyData();
  }

  onSuccessEditHandler = () => {
    this.props.requestCompanyData(`/api/company/${this.props.match.params.id}`);
    this.setState({ showEditModal: false, editModalObj: null });
  };

  render() {
    const { company } = this.props;

    //Breadcrumb links to be shown.
    const breadcrumbData = manualBreadcrumbMapping.apply(this, [
      APP_ROUTES.VIEW_COMPANY_ADMIN,
    ]);

    return (
      <div>
        <Breadcrumb data={breadcrumbData} />
        <Container title={"View Admin"}>
          <Grid container>
            <Grid lg={6}>
              <div className="view-company-admin">
                <div>
                  <h6>Name</h6>
                  <span className="name">{company.companyAdminName}</span>
                </div>
                <Divider />
                <div>
                  <h6>Email</h6>
                  <span>{company.companyAdminEmail}</span>
                </div>
                <Divider />
                <div>
                  <h6>Contact Number</h6>
                  <span>{company.companyAdminContactNumber}</span>
                </div>
                <Divider />
              </div>
            </Grid>
          </Grid>
        </Container>
        {checkUserHasPermission(this.props.me.role, [
          PERMISSIONS.UPDATE_AND_READ_COMPANY,
          PERMISSIONS.COMPANY_ALL_OPS,
        ]) && (
          <ViewButtons
            editMethod={() =>
              this.setState({
                showEditModal: true,
                editModalObj: this.props.company,
              })
            }
          />
        )}
        {this.state.showEditModal && (
          <EditCompanyAdmin
            data={this.state.editModalObj}
            onClose={() =>
              this.setState({ editModalObj: null, showEditModal: false })
            }
            onSuccess={this.onSuccessEditHandler}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.companyListReducer.currentCompanyData,
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompanyData: (urlEndPoint) =>
    dispatch(requestCompanyData(urlEndPoint)),
  resetCompanyData: (urlEndPoint) => dispatch(resetCompanyData(urlEndPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCompanyAdmin);
