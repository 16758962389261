import {
  PUT_COMPANIES_LIST,
  PUT_CURRENT_COMPANY_DATA,
} from "../../constants/action-constants";

const initialState = {
  list: [],
  currentCompanyData: {},
};

const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_COMPANIES_LIST:
      return { ...state, list: action.payload };
    case PUT_CURRENT_COMPANY_DATA:
      return { ...state, currentCompanyData: action.payload };
    default:
      return state;
  }
};

export default companyListReducer;
