import { PUT_SERVICES_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestServicesList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putServicesList(response.data?.data));
    }
  };
};

const putServicesList = (data) => {
  return {
    type: PUT_SERVICES_LIST,
    payload: data,
  };
};
