import React, { Component } from "react";
import CustomInput from "../../components/Input";
import { Card, CardContent, InputAdornment } from "@material-ui/core";
import bottomBar from "../../../assets/images/bottom_image.svg";
import tick from "../../../assets/icons/tick.svg";
import eyeVisible from "../../../assets/icons/eyeVisible.svg";
import eyeHide from "../../../assets/icons/eyeHide.svg";
import FormValidator from "../../../services/FormValidator";
import { postAuthApi } from "../../../webApis/apiResource";
import { webApiCheck } from "../../../services/commonMethods";
import { successToast } from "../../../modules/actions/toast.action";
import { REG_EXPS } from "../../../constants";
import { connect } from "react-redux";
import { ReactComponent as BackKeyIcon } from "../../../assets/icons/back_arrow.svg";
import qs from "query-string";

class ResetPassword extends Component {
  //All query parameters are in this object.
  parsedObject = qs.parse(this.props.location.search);

  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      confirmPassword: "",
      isPasswordShown: false,
      isConfirmPasswordShown: false,
    };

    this.rules = {
      newPassword: { required: true, regExp: REG_EXPS.PASSWORD_VALIDATION },
      confirmPassword: {
        required: true,
        regExp: REG_EXPS.PASSWORD_VALIDATION,
        shouldEqualTo: "newPassword",
        notEqualMessage: "Confirm password doesn't match",
      },
    };

    this.btns = [
      {
        type: "submit",
        btnProps: {
          variant: "contained",
          text: "Submit",
          className: "submit-button",
          method: this.submit,
        },
      },
    ];
  }

  componentDidMount() {
    //If there is no token passed in the url, then redirect user to the login page.
    if (!this.parsedObject.token) {
      return this.props.history.push("/login");
    }
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  togglePassword = () => {
    this.setState(({ isPasswordShown }) => ({
      isPasswordShown: !isPasswordShown,
    }));
  };

  toggleConfirmPassword = () => {
    this.setState(({ isConfirmPasswordShown }) => ({
      isConfirmPasswordShown: !isConfirmPasswordShown,
    }));
  };

  submit = async () => {
    const { confirmPassword, newPassword } = this.state;

    const response = await postAuthApi("/api/auth/resetPassword", {
      confirmPassword: confirmPassword,
      newPassword: newPassword,
      token: this.parsedObject.token,
    });
    if (webApiCheck(response)) {
      this.props.successToast({
        message: "Password reset successfully",
        path: "/login",
      });
    }
  };

  render() {
    const { isPasswordShown, isConfirmPasswordShown } = this.state;
    return (
      <div className="unauth-content rese">
        <Card className="login-card">
          <div className="left-section">
            <div className="left-section-content reset-password">
              <h1>Welcome to Vendify</h1>
              <p>New Password instructions</p>
              <ul>
                <li>
                  <img src={tick} alt="tick" /> Password must contain atleast:
                  <ul className="reset-password-instruction">
                    <li>1 capital letter</li>
                    <li>1 small letter</li>
                    <li>1 special character</li>
                    <li>1 number</li>
                    <li>8 characters in total</li>
                  </ul>
                </li>
              </ul>
            </div>
            <img src={bottomBar} alt="bottom-bar" className="bottom-bar" />
          </div>
          <div className="right-section">
            <CardContent className="form-sec">
              <FormValidator
                rules={this.rules}
                data={this.state}
                btns={this.btns}
              >
                <div className="card-title">Reset Password</div>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: "login-input",
                  }}
                  inputProps={{
                    value: this.state.password,
                    onChange: this.handleChange,
                    placeholder: "New Password",
                    name: "newPassword",
                    required: true,
                    type: isPasswordShown ? "text" : "password",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={isPasswordShown ? eyeHide : eyeVisible}
                            alt="eye"
                            className="eye-icon"
                            onClick={this.togglePassword}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: "login-input",
                  }}
                  inputProps={{
                    value: this.state.password,
                    onChange: this.handleChange,
                    placeholder: "Confirm Password",
                    name: "confirmPassword",
                    required: true,
                    type: isConfirmPasswordShown ? "text" : "password",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={isConfirmPasswordShown ? eyeHide : eyeVisible}
                            alt="eye"
                            className="eye-icon"
                            onClick={this.toggleConfirmPassword}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <div
                  className="reset-password_redirect-login"
                  onClick={() => this.props.history.push("/login")}
                >
                  <i>
                    <BackKeyIcon />
                  </i>
                  <span>Back To Login</span>
                </div>
              </FormValidator>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  successToast: (obj) => dispatch(successToast(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
