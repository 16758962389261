import { IconButton, Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetErrorToast,
  resetSuccessToast,
} from "../../../modules/actions/toast.action";
import CloseIconWhite from "../../../assets/icons/cross_icon_white.svg";
import classnames from "classnames";

export default function CustomSnackBar(props) {
  const { show, message, color } = props;
  const [open, setOpen] = useState(false);
  const toastClasses = classnames({
    [`custom-snackbar-${color}`]: true,
    "custom-snackbar-common": true,
  });

  const dispatchActions = useDispatch();

  useEffect(() => {
    setOpen(show);

    if (show) {
      setTimeout(() => {
        dispatchActions(resetErrorToast());
        dispatchActions(resetSuccessToast());
      }, 4000);
    }
  }, [show]);

  const handleClose = (ev, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatchActions(resetErrorToast());
    dispatchActions(resetSuccessToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        root: toastClasses,
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{message}</span>}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          key={0}
        >
          <img
            src={CloseIconWhite}
            alt="cross"
            className="close-icon-snackbar"
          />
        </IconButton>
      }
    />
  );
}
