import React, { Fragment } from "react";
import { ImagePicker } from "react-file-picker";
import UploadIcon from "../../../assets/images/upload.svg";
import ValidationMessage from "../../../services/ValidationMessage";
import "./CustomImagePicker.scss";

function CustomImagePicker(props) {
  const { name, validation, value, photoName } = props;
  return (
    <Fragment>
      <ImagePicker
        extensions={["jpg", "jpeg", "png"]}
        dims={{ minWidth: 10, minHeight: 10 }}
        {...props}
      >
        {
          <button className="upload-image-container">
            {value ? (
              <img src={value} className="uploaded-image" alt="uploaded-logo" />
            ) : (
              <Fragment>
                <img
                  src={UploadIcon}
                  className="upload-image-icon"
                  alt="upload-icon"
                />
                <br />
                {photoName}
                {validation && <span className="text-danger">*</span>}
              </Fragment>
            )}
          </button>
        }
      </ImagePicker>
      {validation ? <ValidationMessage name={name} /> : ""}
    </Fragment>
  );
}

export default CustomImagePicker;
