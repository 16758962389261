import {
  getAccessToken,
  removeOnlyAccessToken,
  setAccessToken,
} from "../../services/browserStorage";
import { postDashboardWebApi } from "../../webApis/apiResource";
import JwtDecode from "jwt-decode";
import { webApiCheck } from "../../services/commonMethods";

var regenrateTimeout;

export const regenerateToken = () => {
  return async (dispatch) => {
    const response = await postDashboardWebApi(
      getAccessToken(),
      `/api/auth/regenerateToken`
    );
    if (webApiCheck(response)) {
      removeOnlyAccessToken();
      setAccessToken(response.data.token);
      dispatch(checkTokenSessionTime());
    }
  };
};

export const checkTokenSessionTime = () => {
  return (dispatch) => {
    if (
      getAccessToken() !== undefined &&
      getAccessToken() !== "undefined" &&
      getAccessToken() !== null &&
      getAccessToken() !== "null"
    ) {
      const jwt = JwtDecode(getAccessToken());
      const remainingTime = jwt.exp * 1000 - (new Date().getTime() + 20000);
      regenrateTimeout = setTimeout(() => {
        dispatch(regenerateToken());
        clearTimeoutSessionTime();
      }, remainingTime);
    }
  };
};

export const clearTimeoutSessionTime = () => {
  if (regenrateTimeout) {
    clearTimeout(regenrateTimeout);
  }
};
