import { TableCell } from "@material-ui/core";
import React, { Component } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { requestUserList } from "../../../modules/actions/userList.action";
import ActionColumn from "../../components/ActionColumn";
import Container from "../../components/Container";
import DateColumn from "../../components/DateColumn";
import RedirectToView from "../../components/RedirectToView";
import CustomTable from "../../components/Table";
import AddUser from "./AddUser";
import Delete from "../../components/Modals/Delete";
import {
  checkUserHasPermission,
  getUserConfig,
  PERMISSIONS_FOR_USER_ROUTES,
  getDateAndTime,
} from "../../../services/commonMethods";
import { BootstrapTooltip } from "../../components/TopNavbar";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      showAddModal: false,
      showDeleteModal: false,
      userId: null,
      deleteObj: {},
    };

    this.userConfig = getUserConfig(this.props);
  }

  tableHead = () => {
    let head = [
      {
        title: "Name",
        props: {},
      },
      {
        title: "Type",
        props: {},
      },
      {
        title: "Email",
        props: {},
      },
      {
        title: "Date Updated",
        props: {},
      },
      {
        title: "Action",
        props: {},
      },
    ];

    if (
      !checkUserHasPermission(this.props.me.role, this.userConfig.permissions)
    ) {
      //If user doen't have permission then pop the action column.
      head.pop();
    }
    return head;
  };

  componentWillMount() {
    this.props.requestUserList(this.userConfig.urlEndPoint);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userList !== this.props.userList) {
      let temp = [];
      nextProps.userList.forEach((user) => {
        let json = {
          name: (
            <RedirectToView redirectToView={() => this.redirectToView(user)}>
              {" "}
              <div className="text-ellipsis">
                <div
                  className={
                    user.enabled ? "enabled-circle" : "disabled-circle"
                  }
                />
                <BootstrapTooltip
                  title={user.name}
                  placement="top"
                  className="tooltip-text"
                >
                  <span>{user.name}</span>
                </BootstrapTooltip>
              </div>
            </RedirectToView>
          ),
          type: <TableCell>{user.role.name}</TableCell>,
          email: (
            <TableCell className="text-lowercase">
              <div className="text-ellipsis">
                <BootstrapTooltip
                  title={user.email}
                  placement="top"
                  className="tooltip-text cursor-pointer"
                >
                  <span>{user.email}</span>
                </BootstrapTooltip>
              </div>
            </TableCell>
          ),
          updatedOn: (
            <TableCell>{getDateAndTime(user.lastUpdatedOn)}</TableCell>
          ),
          action:
            checkUserHasPermission(
              this.props.me.role,
              PERMISSIONS_FOR_USER_ROUTES[user.role.id]
            ) && user.id !== this.props.me.id ? (
              <ActionColumn
                editMethod={() =>
                  this.setState({ showAddModal: true, userId: user.id })
                }
                deleteMethod={() => this.onDeleteAction(user)}
              />
            ) : !checkUserHasPermission(
                this.props.me.role,
                this.userConfig.permissions
              ) ? undefined : (
              <TableCell />
            ),
        };
        temp.push(json);
      });
      this.setState({ tableData: temp });
    }
  }

  handleAddModalSuccess = () => {
    this.setState(
      {
        showAddModal: false,
        userId: null,
      },
      () => {
        this.props.requestUserList(this.userConfig.urlEndPoint);
      }
    );
  };

  redirectToView = ({ id }) => {
    this.props.history.push(`${this.userConfig.viewPath}/${id}`);
  };

  onDeleteAction = (obj) => {
    this.setState({
      deleteObj: obj,
      showDeleteModal: true,
    });
  };

  onDeleteSuccess = () => {
    this.setState(
      {
        showDeleteModal: false,
        deleteObj: {},
      },
      () => {
        this.props.requestUserList(
          this.userConfig.urlEndPoint,
          this.listPayload
        );
      }
    );
  };
  redirectToView = ({ id }) => {
    this.props.history.push(`/user/${id}`);
  };
  onDeleteAction = (obj) => {
    this.setState({
      deleteObj: obj,
      showDeleteModal: true,
    });
  };

  render() {
    return (
      <Fragment>
        <Container
          title={this.userConfig.title}
          showBtns={
            checkUserHasPermission(
              this.props.me.role,
              this.userConfig.permissions
            )
              ? true
              : false
          }
          btnText={this.userConfig.addBtnText}
          btnMethod={() => this.setState({ showAddModal: true })}
        >
          <CustomTable
            tableHead={this.tableHead()}
            tableData={this.state.tableData}
            refr="user-table"
          />
        </Container>
        {this.state.showAddModal ? (
          <AddUser
            open={this.state.showAddModal}
            onClose={() => this.setState({ showAddModal: false, userId: null })}
            onSuccess={this.handleAddModalSuccess}
            userId={this.state.userId}
          />
        ) : (
          ""
        )}
        {this.state.showDeleteModal ? (
          <Delete
            open={this.state.showDeleteModal}
            onClose={() =>
              this.setState({
                showDeleteModal: false,
                deleteObj: {},
              })
            }
            name={this.state.deleteObj.name}
            urlEndPoint={`/api/user/${this.state.deleteObj.id}`}
            onSuccess={this.onDeleteSuccess}
            redirectPath={this.userConfig.afterDeleteRedirectPath}
          />
        ) : (
          ""
        )}
        {this.state.showDeleteModal ? (
          <Delete
            open={this.state.showDeleteModal}
            onClose={() =>
              this.setState({
                showDeleteModal: false,
                deleteObj: {},
              })
            }
            name={this.state.deleteObj.name}
            onSuccess={this.onDeleteSuccess}
            urlEndPoint={`/api/user/${this.state.deleteObj.id}`}
            redirectPath={this.props.location.pathname}
          />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userList: state.userListReducer.list,
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestUserList: (urlEndPoint) => dispatch(requestUserList(urlEndPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
