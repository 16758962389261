import {
  FETCH_USER_LIST,
  PUT_INTEGRATION_ADMIN_LIST,
  PUT_SUPPORT_ADMIN_LIST,
} from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestUserList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(fetchUserList(response.data));
    }
  };
};

export const requestIntegrationAdminsList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putIntegrationAdminsList(response.data));
    }
  };
};

export const requestSupportAdminsList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putSupportAdminsList(response.data));
    }
  };
};

const fetchUserList = (data) => {
  return {
    type: FETCH_USER_LIST,
    payload: data,
  };
};

const putIntegrationAdminsList = (data) => {
  return {
    type: PUT_INTEGRATION_ADMIN_LIST,
    payload: data,
  };
};

const putSupportAdminsList = (data) => {
  return {
    type: PUT_SUPPORT_ADMIN_LIST,
    payload: data,
  };
};
