import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import DeletePopIcon from "../../../../assets/images/delete_popup_icon.svg";
import CustomButton from "../../Button";
import { useDispatch, useSelector } from "react-redux";
import { resetCancelConfirm } from "../../../../modules/actions/cancelConfirm.action";
import "../modals.scss";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function CancelConfirm(props) {
  const [show, setShow] = useState(false);
  const { open, onClose, location } = props;
  const currentLocation = usePrevious(location);

  const dispatchActions = useDispatch();
  const state = useSelector((state) => state.cancelConfirmReducer);

  useEffect(() => {
    setShow(open);
  }, [open]);

  useEffect(() => {
    if (currentLocation && currentLocation.pathname !== location.pathname) {
      dispatchActions(resetCancelConfirm());
    }
  }, [location]);

  const leavePage = () => {
    state.redirectMethod();
    dispatchActions(resetCancelConfirm());
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      classes={{ paper: "delete-modal" }}
      fullWidth={true}
    >
      <DialogTitle className="text-center">
        <img
          src={DeletePopIcon}
          alt="delete-popup"
          className="delete-popup-icon"
        />
      </DialogTitle>
      <DialogContent className="text-center delete-dialog-content">
        <h3 style={{ fontSize: "28px" }}>You currently have unsaved changes</h3>
        <span>Are you sure you want to exit without saving?</span>
        <br />
        <br />
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className="text-center mt-4">
            <CustomButton
              type="button"
              btnProps={{
                text: "Stay on this page",
                className: "cancel-btn",
                variant: "contained",
                onClick: onClose,
              }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CustomButton
              type="button"
              btnProps={{
                text: "Leave this page",
                className: "cancel-btn delete-btn",
                variant: "contained",
                onClick: leavePage,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(CancelConfirm);
