import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import React from "react";
import EditIcon from "../../../assets/icons/edit.svg";
import DeleteIcon from "../../../assets/icons/trash-bin.svg";

export default function ActionColumn(props) {
  const { editMethod, deleteMethod } = props;
  return (
    <TableCell>
      <Tooltip title="Edit" placement="top">
        <IconButton
          aria-label="Edit"
          className="action-btn"
          onClick={editMethod}
        >
          <img src={EditIcon} alt="edit" />
        </IconButton>
      </Tooltip>
      &nbsp;&nbsp;&nbsp;&nbsp;
      {deleteMethod && (
        <Tooltip title="Delete" placement="top">
          <IconButton
            aria-label="Delete"
            className="action-btn"
            onClick={deleteMethod}
          >
            <img src={DeleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
