import { FormControl } from "@material-ui/core";
import React, { useContext } from "react";
import { ValidationContext } from "../../../services/FormValidator";
import ValidationMessage from "../../../services/ValidationMessage";

export default function CustomInput(props) {
  const { formControlProps, inputProps } = props;
  const contextValid = useContext(ValidationContext);
  return (
    <FormControl
      {...formControlProps}
      autoComplete="off"
      className="MuiInput-underline"
    >
      <div className="upload-file_parent">
        <span>{inputProps.label}</span>
        <input type="file" {...inputProps} />
      </div>
      {contextValid &&
      contextValid.errors &&
      contextValid.errors[inputProps.name] ? (
        <ValidationMessage name={inputProps.name} />
      ) : inputProps.required ? (
        <p />
      ) : (
        ""
      )}
    </FormControl>
  );
}
