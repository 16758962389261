import React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { breadcrumbMapping } from "../../../constants/breadcrumbMapping";
import { checkShowModal } from "../../../modules/actions/cancelConfirm.action";

function Breadcrumbs(props) {
  const data = breadcrumbMapping[props.match.path] || props.data;
  const formDirtBool = useSelector(
    (state) => state.cancelConfirmReducer.formDirtBool
  );
  const dispatchActions = useDispatch();

  const handleClick = (item) => {
    if (formDirtBool) {
      dispatchActions(checkShowModal(() => props.history.push(item.url)));
    } else {
      props.history.push(item.url);
    }
  };

  if (!data) {
    return <div></div>;
  }

  return (
    <div className="dash-header">
      <div className="left-header">
        <ul>
          {data.map((item, index) => (
            <Fragment key={index}>
              {item.url ? (
                <li
                  onClick={() => handleClick(item)}
                  className="active-breadcrumb"
                >
                  {item.title}
                </li>
              ) : (
                <li>{item.title}</li>
              )}
              {index < data.length - 1 ? <li className="backslash">/</li> : ""}
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default withRouter(Breadcrumbs);
