import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import DeletePopIcon from "../../../assets/images/delete_popup_icon.svg";
import { successToast } from "../../../modules/actions/toast.action";
import { getAccessToken } from "../../../services/browserStorage";
import { webApiCheck } from "../../../services/commonMethods";
import { deleteWebApi } from "../../../webApis/apiResource";
import CustomButton from "../Button";

function Delete(props) {
  const [show, setShow] = useState(false);
  const { open, onClose, name, urlEndPoint, onSuccess, redirectPath } = props;

  const dispatchActions = useDispatch();

  useEffect(() => {
    setShow(open);
  }, [open]);

  const onDelete = async () => {
    const response = await deleteWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatchActions(
        successToast({
          message: `${name} deleted successfully`,
          path: redirectPath,
        })
      );
      if (typeof onSuccess === "function") {
        onSuccess();
      } else {
        onClose();
      }
    }
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      classes={{ paper: "delete-modal" }}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle className="text-center">
        <img
          src={DeletePopIcon}
          alt="delete-popup"
          className="delete-popup-icon"
        />
      </DialogTitle>
      <DialogContent className="text-center delete-dialog-content">
        <h3>Are you sure?</h3>
        <span>
          you will not be able to recover <label>{name}</label>
        </span>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className="text-center mt-4">
            <CustomButton
              type="button"
              btnProps={{
                text: "Cancel",
                className: "cancel-btn",
                variant: "contained",
                onClick: onClose,
              }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CustomButton
              type="button"
              btnProps={{
                text: "Yes, delete it!",
                className: "delete-btn",
                variant: "contained",
                onClick: onDelete,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(Delete);
