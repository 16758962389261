import { FormControl, TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { ValidationContext } from "../../../services/FormValidator";
import ValidationMessage from "../../../services/ValidationMessage";

export default function CustomInput(props) {
  const { formControlProps, inputProps } = props;
  const contextValid = useContext(ValidationContext);
  return (
    <FormControl {...formControlProps} autoComplete="off">
      <TextField {...inputProps}>{props.children}</TextField>
      {contextValid &&
      contextValid.errors &&
      contextValid.errors[inputProps.name] ? (
        <ValidationMessage name={inputProps.name} />
      ) : (
        <p />
      )}
    </FormControl>
  );
}
