import { PUT_VENDING_MACHINE_BOARD_LIST } from "../../constants/action-constants";

const initialState = {
  list: [],
};

const VendingMachineBoardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_VENDING_MACHINE_BOARD_LIST:
      return { list: action.payload };
    default:
      return state;
  }
};

export default VendingMachineBoardsReducer;
