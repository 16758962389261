import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import {
  getDateAndTime,
  getCurrentCompanyServices,
} from "../../../services/commonMethods";
import { ENUMS } from "../../../constants";

class LicenseModal extends Component {
  btns = [
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Close"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: this.props.onClose,
      },
    },
  ];

  render() {
    const { licenseVO, validFrom, validTo, type } = this.props.data;
    const { description } = licenseVO;

    return (
      <Dialog classes={{ paper: "company-license-modal" }} open={true}>
        <DialogTitle className="dialog-title">
          Default License Agreement
          <img
            src={CrossWhiteIcon}
            onClick={this.props.onClose}
            alt="cross"
            style={{ transform: "translateY(0px)" }}
          />
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Grid container>
            <Grid item lg={12}>
              <div className="company-license table-view">
                {description && (
                  <div>
                    <h6>Description</h6>
                    <span className="ml-3">{description}</span>
                  </div>
                )}
                <div>
                  <h6 style={{ width: "21%" }}>License Validity :</h6>
                  <span className="">
                    {getDateAndTime(validFrom, 1)} to{" "}
                    {getDateAndTime(validTo, 1)}
                  </span>
                </div>
                <div>
                  <h6>Default Service :</h6>
                  <ul className="company-license-services">
                    {getCurrentCompanyServices(
                      type === ENUMS.COMPANY_TYPE.WITH_FRANCHISE
                    ).map((ele) => (
                      <li>
                        <span>{ele.title}</span>
                        {ele.description}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Divider />
            </Grid>
            <Grid lg={10} />
            <Grid lg={2}>
              <button
                onClick={this.props.onClose}
                className="save-btn float-right"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;&nbsp;
              </button>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
      </Dialog>
    );
  }
}

export default LicenseModal;
