import { FETCH_COMPANY, RESET_COMPANY } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestCompany = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(fetchCompany(response.data));
    }
  };
};

const fetchCompany = (data) => {
  return {
    type: FETCH_COMPANY,
    payload: data,
  };
};

export const resetCompany = () => {
  return {
    type: RESET_COMPANY,
  };
};
