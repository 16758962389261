import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment } from "react";
import ValidationMessage from "../../../services/ValidationMessage";
import { ValidationContext } from "../../../services/FormValidator";

export default function CustomAutoComplete(props) {
  const { autoCompleteProps, textFieldProps } = props;
  let { oneSelectionAllowedOnly } = autoCompleteProps;
  const contextValid = useContext(ValidationContext);

  let getOptions = () => {
    autoCompleteProps.options = autoCompleteProps.options.filter((ele) => {
      let bool = true;
      autoCompleteProps.value.forEach((Element) => {
        if (Element.id === ele.id) {
          bool = false;
        }
      });
      return bool;
    });
    return autoCompleteProps.options;
  };

  return (
    <Fragment>
      <Autocomplete
        {...autoCompleteProps}
        options={
          oneSelectionAllowedOnly ? getOptions() : autoCompleteProps.options
        }
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              //User agents (browsers) ignore autoComplete off now, any random string work instead
              autoComplete: "master-disabled",
            }}
          />
        )}
      />
      {contextValid &&
      contextValid.errors &&
      contextValid.errors[autoCompleteProps.name] ? (
        <ValidationMessage name={autoCompleteProps.name} />
      ) : autoCompleteProps.required ? (
        <p />
      ) : (
        ""
      )}
    </Fragment>
  );
}
