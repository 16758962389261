import React, { Component } from "react";
import Container from "../../components/Container";
import FormValidator from "../../../services/FormValidator";
import { Divider, Grid, MenuItem } from "@material-ui/core";
import CustomInput from "../../components/Input";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import SaveIcon from "../../../assets/icons/save.svg";
import { connect } from "react-redux";
import {
  successToast,
  errorToast,
} from "../../../modules/actions/toast.action";
import { requestCountriesList } from "../../../modules/actions/countries.action";
import { postDashboardWebApi } from "../../../webApis/apiResource";
import {
  requestUserList,
  requestIntegrationAdminsList,
  requestSupportAdminsList,
} from "../../../modules/actions/userList.action";
import { getAccessToken } from "../../../services/browserStorage";
import {
  enableFormDirtiness,
  getComponentTexts,
  webApiCheck,
} from "../../../services/commonMethods";
import { requestCompaniesList } from "../../../modules/actions/companyList.action";
import {
  checkShowModal,
  setFormDirtiness,
  resetFormDirtiness,
} from "../../../modules/actions/cancelConfirm.action";
import CustomAutoComplete from "../../components/AutoComplete/AutoComplete";

class AddCompany extends Component {
  state = {
    companyAdminContactNumber: "",
    companyAdminEmail: "",
    companyAdminName: "",
    operationalCountry: "",
    integrationAdminIds: [],
    supportAdminId: null,
    name: "",
    primaryIntegrationAdminId: null,
    url: "",
    //Default is today's date
    validFrom: null,
    validTo: null,
    paymentGateway: null,
  };
  componentWillUnmount() {
    this.props.resetFormDirtiness();
  }

  componentDidMount() {
    //get Integration admins list.
    this.props
      .requestIntegrationAdminsList("/api/user/get/all?roleId=3")
      .then(() => {
        //If user accessing add-company url directly.
        //If there are no integration admin, show a toast & redirect user to the previous page
        if (!this.props.integrationAdminsList.length) {
          this.props.errorToast({
            data: {
              message:
                "No integration admins exist in the system, cannot add company without an integration admin",
            },
          });
          this.props.history.goBack();
        }
      });
    //get Support Admins list.
    this.props.requestSupportAdminsList("/api/user/get/all?roleId=4");
    //get Countries list.
    this.props.requestCountriesList("/api/country");
  }

  handleChange = (ev) => {
    let payload = {
      [ev.target.name]: ev.target.value,
    };
    if (ev.target.name === "operationalCountry") {
      let selectedCountryData = this.props.countriesList.find(
        (ele) => ele.name === ev.target.value
      );
      payload.operationalCountryId = selectedCountryData.id;
      payload.operationalCountryCode = selectedCountryData.iso2;
    }

    return this.setState(payload, enableFormDirtiness.bind(this));
  };

  handleIntegrationAdminChange = (ev, value) => {
    let primaryIntegrationAdminId = this.state.primaryIntegrationAdminId;
    if (value.length === 0) {
      primaryIntegrationAdminId = null;
    } else if (value.length > 1) {
      primaryIntegrationAdminId = value[0].id;
    }

    this.setState(
      { integrationAdminIds: value, primaryIntegrationAdminId },
      enableFormDirtiness.bind(this)
    );
  };

  handleOperationalCountryChange = (ev, value) => {
    let payload = { operationalCountry: value };
    let selectedCountryData;
    if (value) {
      selectedCountryData = this.props.countriesList.find(
        (ele) => ele.id === value.id
      );
      payload.operationalCountryId = selectedCountryData.id;
      payload.operationalCountryCode = selectedCountryData.iso2;
    }
    return this.setState(payload, enableFormDirtiness.bind(this));
  };

  submit = () => {
    let data = { ...this.state };

    //Converting time into milliseconds.
    data.validFrom = new Date(data.validFrom).getTime();
    data.validTo = new Date(data.validTo).getTime();
    if (data.primaryIntegrationAdminId === null) {
      data.primaryIntegrationAdminId = data.integrationAdminIds[0].id;
    }
    data.integrationAdminIds = data.integrationAdminIds.map((ele) => ele.id);
    data.operationalCountry = data.operationalCountry.name;
    delete data.supportAdminsList;
    delete data.integrationAdminsList;

    let urlEndPoint = `/api/company`;

    postDashboardWebApi(getAccessToken(), urlEndPoint, data).then(
      (Response) => {
        if (webApiCheck(Response)) {
          this.props.successToast({
            message: `${data.name} created successfully!`,
          });
          this.props.requestCompaniesList("/api/company");
          this.props.requestUserList("/api/user/get/all");
          this.props.history.push("/companies");
        }
      }
    );
  };

  btns = [
    {
      type: "submit",
      btnProps: {
        text: [<img src={SaveIcon} alt="save" key={0} />, "Save"],
        className: "save-btn float-right",
        variant: "contained",
        method: this.submit,
      },
    },
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Cancel"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: () => {
          this.props.history.goBack();
        },
      },
    },
  ];

  rules = {
    name: { required: true },
    operationalCountry: { required: true },
    url: { url: true },
    validFrom: { required: true },
    validTo: { required: true },
    companyAdminContactNumber: {
      required: true,
      isMobile: true,
      exactlength: 10,
    },
    companyAdminName: { required: true },
    companyAdminEmail: { required: true, email: true },
    integrationAdminIds: { required: true },
  };

  render() {
    let { supportAdminsList } = this.props;
    const componentStrings = getComponentTexts("get_helper_text");
    return (
      <Container title={"Add Company"}>
        <FormValidator
          rules={this.rules}
          data={this.state}
          btns={this.btns}
          errorToast={this.props.errorToast}
        >
          <Grid container>
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.name,
                  onChange: this.handleChange,
                  required: true,
                  label: "Name",
                  name: "name",
                  type: "text",
                }}
              />
            </Grid>
            <Grid item lg={7} />
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.url,
                  onChange: this.handleChange,
                  label: "URL",
                  name: "url",
                  type: "url",
                  helperText: componentStrings.url_helper_text,
                }}
              />
            </Grid>
            <Grid item lg={1} />
            <Grid item lg={5}>
              <CustomAutoComplete
                autoCompleteProps={{
                  id: "select-operational-country",
                  name: "operationalCountry",
                  options: this.props.countriesList,
                  fullWidth: true,
                  value: this.state.operationalCountry,
                  onChange: this.handleOperationalCountryChange,
                  getOptionLabel: (option) =>
                    option && option.name ? option.name : "",
                  openOnFocus: true,
                  required: true,
                  style: {
                    width: "100%",
                    overflow: "hidden",
                  },
                }}
                textFieldProps={{
                  label: "Operational Country Name",
                  className: "input-add-screen mt-3 custom-auto-padding",
                  variant: "outlined",
                  required: true,
                }}
              />
            </Grid>
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.validFrom,
                  onChange: this.handleChange,
                  InputLabelProps: {
                    shrink: true,
                  },
                  label: "Subscription Valid From",
                  name: "validFrom",
                  type: "date",
                  required: true,
                }}
              />
            </Grid>

            <Grid item lg={1} />
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.validTo,
                  onChange: this.handleChange,
                  InputLabelProps: {
                    shrink: true,
                  },
                  required: true,
                  label: "Subscription Valid To",
                  name: "validTo",
                  type: "date",
                  inputProps: {
                    min: new Date().getInputDateFormat(),
                  },
                }}
              />
            </Grid>
            <Grid item lg={5}>
              <CustomAutoComplete
                autoCompleteProps={{
                  id: "multiselect-integration-admins",
                  name: "integrationAdminIds",
                  oneSelectionAllowedOnly: true,
                  options: this.props.integrationAdminsList,
                  fullWidth: true,
                  multiple: true,
                  value: this.state.integrationAdminIds,
                  onChange: this.handleIntegrationAdminChange,
                  getOptionLabel: (option) =>
                    option && option.name ? option.name : "",
                  disableCloseOnSelect: true,
                  openOnFocus: true,
                  required: true,
                  style: {
                    width: "100%",
                    overflow: "hidden",
                  },
                }}
                textFieldProps={{
                  label: "Integration Admins",
                  className: "input-add-screen mt-3 custom-auto-padding",
                  variant: "outlined",
                  required: true,
                }}
              />
            </Grid>
            <Grid lg={1} />
            <Grid lg={5}>
              {this.state.integrationAdminIds.length > 1 && (
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                  }}
                  inputProps={{
                    value: this.state.primaryIntegrationAdminId,
                    onChange: this.handleChange,
                    required: true,
                    label: "Primary Integration Admin",
                    name: "primaryIntegrationAdminId",
                    select: true,
                  }}
                >
                  {this.state.integrationAdminIds.map((admin) => (
                    <MenuItem value={admin.id} key={admin.id}>
                      {admin.name}
                    </MenuItem>
                  ))}
                </CustomInput>
              )}
            </Grid>
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.supportAdminId,
                  onChange: this.handleChange,
                  required: false,
                  label: "Support Admin",
                  name: "supportAdminId",
                  select: true,
                }}
              >
                {supportAdminsList.map((admin) => (
                  <MenuItem value={admin.id} key={admin.id}>
                    {admin.name}
                  </MenuItem>
                ))}
              </CustomInput>
            </Grid>
            <Grid lg={12}>
              <h6 className="block-separator-heading">Company Admin Details</h6>
            </Grid>
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.companyAdminName,
                  onChange: this.handleChange,
                  required: true,
                  label: "Name",
                  name: "companyAdminName",
                  type: "text",
                }}
              />
            </Grid>
            <Grid lg={7} />
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.companyAdminEmail,
                  onChange: this.handleChange,
                  required: true,
                  label: "Email",
                  name: "companyAdminEmail",
                  type: "email",
                }}
              />
            </Grid>
            <Grid lg={1} />
            <Grid item lg={5}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  margin: "normal",
                }}
                inputProps={{
                  value: this.state.companyAdminContactNumber,
                  onChange: this.handleChange,
                  required: true,
                  label: "Contact Number",
                  name: "companyAdminContactNumber",
                  type: "text",
                }}
              />
            </Grid>
          </Grid>
          <Divider />
        </FormValidator>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  countriesList: state.countriesListReducer.list,
  paymentGatewaysList: state.paymentGatewaysListReducer.list,
  integrationAdminsList: state.userListReducer.integrationAdminsList,
  supportAdminsList: state.userListReducer.supportAdminsList,
  formDirtBool: state.cancelConfirmReducer.formDirtBool,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
  requestCountriesList: (urlEndPoint) =>
    dispatch(requestCountriesList(urlEndPoint)),
  successToast: (obj) => dispatch(successToast(obj)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  requestUserList: (urlEndPoint) => dispatch(requestUserList(urlEndPoint)),
  requestIntegrationAdminsList: (urlEndPoint) =>
    dispatch(requestIntegrationAdminsList(urlEndPoint)),
  requestSupportAdminsList: (urlEndPoint) =>
    dispatch(requestSupportAdminsList(urlEndPoint)),
  checkShowModal: (method) => dispatch(checkShowModal(method)),
  setFormDirtiness: () => dispatch(setFormDirtiness()),
  resetFormDirtiness: () => dispatch(resetFormDirtiness()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
