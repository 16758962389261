import { FETCH_ME } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestMe = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(fetchMe(response.data));
    }
  };
};

const fetchMe = (data) => {
  return {
    type: FETCH_ME,
    payload: data,
  };
};
