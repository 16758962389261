import {
  ClickAwayListener,
  Drawer,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import VendifyLogo from "../../../assets/icons/vendify_logo.svg";
import UserIcon from "../../../assets/icons/user.svg";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../modules/actions/login.action";
import AccountIconNew from "../../../assets/icons/user_managemnt_black.svg";
import MailIcon from "../../../assets/icons/mail.svg";
import logOutIcon from "../../../assets/icons/off.svg";
import RoleIcon from "../../../assets/icons/security.svg";

import { makeStyles } from "@material-ui/core/styles";
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function TopNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatchActions = useDispatch();
  const me = useSelector((state) => state.meReducer.data);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <Drawer
        anchor="top"
        variant="permanent"
        elevation={10}
        classes={{
          paperAnchorTop: "top-navbar",
        }}
      >
        <Grid container>
          <Grid item lg={2}>
            <img src={VendifyLogo} alt="vendify" className="vendify-logo" />
          </Grid>
          <Grid item lg={10}>
            <div className="dropdown-icon">
              <IconButton
                size="medium"
                color="secondary"
                className="navbar-icon-btn"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <img src={UserIcon} alt="user" />
                <span className="profile-name">&nbsp; {me.name}</span>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Drawer>
      <Popper
        open={open}
        className="profile-dropdown"
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "9999", marginTop: "65px" }}
      >
        {({ TransitionProps, placement }) => (
          <div className="profile-drawer">
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    className="menu-item-profile"
                    onKeyDown={handleListKeyDown}
                  >
                    <p className="profile-title">My Profile</p>
                    <MenuItem>
                      <img src={AccountIconNew} alt="slide-btn" /> {me.name}
                    </MenuItem>
                    <MenuItem>
                      <img src={RoleIcon} alt="slide-btn" />
                      {me.role?.name}
                    </MenuItem>
                    <BootstrapTooltip
                      title={me.email}
                      placement="left"
                      className="tooltip-text"
                    >
                      <MenuItem>
                        <img src={MailIcon} alt="slide-btn" />{" "}
                        <span>{me.email}</span>
                      </MenuItem>
                    </BootstrapTooltip>
                    <MenuItem
                      onClick={(ev) => {
                        dispatchActions(logOut());
                        handleClose(ev);
                      }}
                    >
                      <img src={logOutIcon} alt="slide-btn" /> Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </div>
        )}
      </Popper>
    </Fragment>
  );
}

export default withRouter(TopNavbar);
