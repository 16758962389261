import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import InputColor from "react-input-color";
import { ENUMS } from "../../../constants";
import CustomImagePicker from "../CustomImagePicker/CustomImagePicker";
import { ReactComponent as CrossIconWhite } from "../../../assets/icons/cross-circle.svg";

export default function DevicePreviewPicker({
  primaryColor,
  secondaryColor,
  logo,
  logoPlaceholder,
  setDevicePreviewState,
}) {
  //Onchange of react-input-color hits atleast once, so to prevent it we are using a state variable.
  const [colorChangeIsEnabled, setColorChangeIsEnabled] = useState(false);

  const enableColorChange = () => {
    return setColorChangeIsEnabled(true);
  };

  const PRIMARY_COLOR_KEY = "primaryColor",
    SECONDARY_COLOR_KEY = "secondaryColor";

  // check if the default color value comes then cross icon will not visible
  function checkDefaultColorNotSelected(key) {
    if (key === PRIMARY_COLOR_KEY) {
      return primaryColor !== ENUMS.DEVICE_APP_COLOR_CODES.PRIMARY;
    } else if (key === SECONDARY_COLOR_KEY) {
      return secondaryColor !== ENUMS.DEVICE_APP_COLOR_CODES.SECONDARY;
    }
  }

  // this function will update the primary and secondary color
  function handleColorChange(color, name) {
    if (colorChangeIsEnabled) {
      return setDevicePreviewState({ [name]: color.hex });
    }
  }

  //It removes selected color and selects default color
  function removeSelectedColor(colorChangeId) {
    if (colorChangeId === PRIMARY_COLOR_KEY) {
      return setDevicePreviewState({
        primaryColor: ENUMS.DEVICE_APP_COLOR_CODES.PRIMARY,
      });
    } else if (colorChangeId === SECONDARY_COLOR_KEY) {
      return setDevicePreviewState({
        secondaryColor: ENUMS.DEVICE_APP_COLOR_CODES.SECONDARY,
      });
    }
  }

  // this function will update the logo
  const handleLogoChange = (base64) => {
    return setDevicePreviewState({
      logo: base64,
    });
  };

  return (
    <Grid container>
      <Grid lg={5} item>
        <CustomImagePicker
          value={logo}
          photoName={logoPlaceholder}
          onChange={handleLogoChange}
        />
      </Grid>
      <Grid lg={7} onClick={enableColorChange}>
        <label className="input-label">
          Primary Color
          {checkDefaultColorNotSelected(PRIMARY_COLOR_KEY) && (
            <i
              onClick={removeSelectedColor.bind(null, PRIMARY_COLOR_KEY)}
              className="cursor-pointer ml-1"
            >
              <CrossIconWhite />
            </i>
          )}
        </label>
        <div>
          <InputColor
            initialValue={primaryColor}
            disableAlpha="true"
            value={primaryColor}
            onChange={(color) => handleColorChange(color, PRIMARY_COLOR_KEY)}
            placement="right"
            className="input-color-picker"
          />
        </div>
        <label className="input-label mt-3">
          Secondary Color
          {checkDefaultColorNotSelected(SECONDARY_COLOR_KEY) && (
            <i
              onClick={removeSelectedColor.bind(null, SECONDARY_COLOR_KEY)}
              className="cursor-pointer ml-1"
            >
              <CrossIconWhite />
            </i>
          )}
        </label>
        <div>
          <InputColor
            initialValue={secondaryColor}
            value={secondaryColor}
            onChange={(color) => handleColorChange(color, SECONDARY_COLOR_KEY)}
            placement="right"
            className="input-color-picker"
          />
        </div>
      </Grid>
    </Grid>
  );
}
