import { FETCH_ROLES } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestRoles = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(fetchRoles(response.data.data));
    }
  };
};

const fetchRoles = (data) => {
  return {
    type: FETCH_ROLES,
    payload: data,
  };
};
