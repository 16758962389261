//Routes constant of the app.
export const APP_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  LIST_COMPANIES: "/companies",
  VIEW_COMPANY: "/companies/:id",
  EDIT_COMPANY: "/companies/edit-company/:id",
  ADD_COMPANY: "/companies/add-company",
  VIEW_USER: "/user/:id",
  LIST_USERS: "/users",
  LIST_COMPANY_ADMINS: "/company-admins",
  VIEW_COMPANY_ADMIN: "/company-admin/:id",
  LIST_LICENSES: "/licenses",
  LIST_INTEGRATION_PARTNERS: "/integration-partners",
  LIST_INTEGRATION_ADMINS: "/integration-admins",
  LIST_SUPPORT_ADMINS: "/support-admins",
};
