import {
  PUT_COMPANIES_LIST,
  PUT_CURRENT_COMPANY_DATA,
} from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { fetchWebApi } from "../../webApis/apiResource";

export const requestCompaniesList = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putCompaniesList(response.data));
    }
  };
};

export const requestCompanyData = (urlEndPoint) => {
  return async (dispatch) => {
    const response = await fetchWebApi(getAccessToken(), urlEndPoint);
    if (webApiCheck(response)) {
      dispatch(putCompanyData(response.data));
    }
  };
};

export const resetCompanyData = (urlEndPoint) => {
  return (dispatch) => {
    dispatch(putCompanyData({}));
  };
};

const putCompaniesList = (data) => {
  return {
    type: PUT_COMPANIES_LIST,
    payload: data,
  };
};

const putCompanyData = (data) => {
  return {
    type: PUT_CURRENT_COMPANY_DATA,
    payload: data,
  };
};
