import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getAccessToken } from "./services/browserStorage";
import AuthRoutes from "./routeManagement/AuthRoutes";
import UnAuthRoutes from "./routeManagement/UnAuthRoutes";
import { loginSuccess, logOut } from "./modules/actions/login.action";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { errorToast } from "./modules/actions/toast.action";
import {
  getComponentTexts,
  objectHasKeysCheck,
} from "./services/commonMethods";
import CustomSnackBar from "./pages/components/Snackbar";
import { DEFAULT_ERROR_MESSAGE } from "./constants/index";
import { checkTokenSessionTime } from "./modules/actions/regenerateToken.action";
import { requestMe } from "./modules/actions/me.action";
import { catchApiErrors } from "./services/Sentry";

export const LoaderContext = React.createContext(false);

class Gatekeeper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authToken:
        getAccessToken() &&
        !window.location.href.match("/forgot-password") &&
        !window.location.href.match("/reset-password")
          ? true
          : false,
      showLoader: false,
    };

    if (this.state.authToken) {
      this.props.loginSuccess();
      this.props.requestMe("/api/user/me");
    }
  }

  componentWillMount() {
    const sentryMessages = getComponentTexts("sentry");
    //keeps record of total api calls requested.
    let totalApiRequested = 0;
    let totalApiResponded = 0;

    const getResponseFromError = function (error) {
      try {
        return error.response.data;
      } catch {
        //If there is no repsonse then it means there is Network error
        return sentryMessages.network_error_message;
      }
    };

    axios.interceptors.response.use(
      (response) => {
        //If total api that were requested are fulfilled, only then change the loader
        if (++totalApiResponded === totalApiRequested) {
          document.body.style.pointerEvents = "auto";
          this.setState({ showLoader: false });
        }
        return response;
      },
      (error) => {
        let apiResponse = getResponseFromError(error);
        this.props.errorToast(error.response);
        //If total api that were requested are fulfilled, only then change the loader
        if (++totalApiResponded === totalApiRequested) {
          this.setState({ showLoader: false });
          document.body.style.pointerEvents = "auto";
        }

        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          error.response.data.message.toLowerCase() === "token expired"
        ) {
          this.props.logOut();
        }
        catchApiErrors(error, apiResponse);
        return error;
      }
    );
    axios.interceptors.request.use(
      (response) => {
        totalApiRequested++;
        document.body.style.pointerEvents = "none";
        this.setState({ showLoader: true });
        return response;
      },
      (error) => {
        let apiResponse = getResponseFromError(error);

        catchApiErrors(error, apiResponse);
        return error;
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn !== this.props.loggedIn) {
      this.setState(
        {
          authToken:
            window.location.href.match("/forgot-password") ||
            window.location.href.match("/reset-password")
              ? false
              : nextProps.loggedIn,
        },
        () => {
          if (this.state.authToken) {
            this.props.checkTokenSessionTime();
          }
        }
      );
    }
  }

  render() {
    return (
      <Fragment>
        <LoaderContext.Provider value={this.state.showLoader}>
          {this.state.authToken ? <AuthRoutes /> : <UnAuthRoutes />}
          {this.state.showLoader ? (
            <CircularProgress
              classes={{
                root: "circular-loader",
                colorPrimary: "circular-loader-color",
              }}
            />
          ) : (
            ""
          )}
        </LoaderContext.Provider>
        {objectHasKeysCheck(this.props.errorToastObj) ? (
          <CustomSnackBar
            color="danger"
            show={objectHasKeysCheck(this.props.errorToastObj)}
            message={
              objectHasKeysCheck(this.props.errorToastObj)
                ? this.props.errorToastObj.data &&
                  this.props.errorToastObj.data.message
                  ? this.props.errorToastObj.data.message
                  : DEFAULT_ERROR_MESSAGE
                : DEFAULT_ERROR_MESSAGE
            }
          />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.loginReducer.loggedIn,
  errorToastObj: state.toastReducer.errorToastObj,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: () => dispatch(loginSuccess()),
  logOut: () => dispatch(logOut()),
  errorToast: (obj) => dispatch(errorToast(obj)),
  checkTokenSessionTime: () => dispatch(checkTokenSessionTime()),
  requestMe: (urlEndPoint) => dispatch(requestMe(urlEndPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gatekeeper);
