import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Grid, List, ListItem } from "@material-ui/core";
import {
  requestUser,
  resetUser,
} from "../../../modules/actions/viewUser.action";
import Container from "../../components/Container";
import {
  getDateAndTime,
  getUserConfig,
  objectHasKeysCheck,
  checkUserHasPermission,
  PERMISSIONS_FOR_USER_ROUTES,
} from "../../../services/commonMethods";
import ViewButtons from "../../components/ViewButtons";
import AddUser from "./AddUser";
import Delete from "../../components/Modals/Delete";
import { checkIfUserHasPathHistory } from "../../../services/browserStorage";

class ViewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
      showDeleteModal: false,
    };

    this.userConfig = getUserConfig(this.props);
  }

  componentWillMount() {
    this.props.requestUser(`/api/user/${this.props.match.params.id}`);
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  handleEditModalSuccess = () => {
    this.setState({ showEditModal: false });
    this.props.requestUser(`/api/user/${this.props.match.params.id}`);
  };
  handleDeleteModalSuccess = () => {
    this.props.history.goBack();
  };
  render() {
    const { user } = this.props;
    return objectHasKeysCheck(user) ? (
      <React.Fragment>
        <Container title={this.userConfig.viewPageTitle}>
          <Grid container>
            <Grid item lg={6} md={7} sm={10}>
              <List className="view-box">
                <ListItem>
                  <label>User Name :</label>
                  <span className="name">{user.name}</span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Mobile :</label>
                  <span>{user.contactNumber}</span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Email :</label>
                  <span>{user.email}</span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Role :</label>
                  <span>{user.role.name}</span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Status :</label>
                  <span
                    className={
                      user.enabled ? "active-status" : "inactive-status"
                    }
                  >
                    {user.enabled ? "Active" : "Inactive"}
                  </span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Date Created :</label>
                  <span>{getDateAndTime(user.createdOn)}</span>
                </ListItem>
                <Divider />
                <ListItem>
                  <label>Last Updated :</label>
                  <span>{getDateAndTime(user.lastUpdatedOn)}</span>
                </ListItem>
                <Divider />
              </List>
            </Grid>
          </Grid>
        </Container>
        {checkUserHasPermission(
          this.props.me.role,
          PERMISSIONS_FOR_USER_ROUTES[user.role.id]
        ) &&
          user.id !== this.props.me.id && (
            <ViewButtons
              editMethod={() => this.setState({ showEditModal: true })}
              deleteMethod={() => this.setState({ showDeleteModal: true })}
            />
          )}
        {this.state.showEditModal ? (
          <AddUser
            open={this.state.showEditModal}
            onClose={() => this.setState({ showEditModal: false })}
            onSuccess={this.handleEditModalSuccess}
            userId={this.props.match.params.id}
          />
        ) : (
          ""
        )}
        {this.state.showDeleteModal ? (
          <Delete
            open={this.state.showDeleteModal}
            onClose={() =>
              this.setState({
                showDeleteModal: false,
              })
            }
            name={user.name}
            urlEndPoint={`/api/user/${this.props.match.params.id}`}
            onSuccess={
              checkIfUserHasPathHistory() && this.handleDeleteModalSuccess
            }
            redirectPath={checkIfUserHasPathHistory() ? undefined : "/users"}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.viewUserReducer.data,
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  requestUser: (urlEndPoint, payload) =>
    dispatch(requestUser(urlEndPoint, payload)),
  resetUser: () => dispatch(resetUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
