import {
  getComponentTexts,
  userIsCompanyAdmin,
} from "../services/commonMethods";
import { APP_ROUTES } from "./appRoutes";

const Strings = getComponentTexts("page_titles");

//Breadcrumb mapping.
export const breadcrumbMapping = {
  [APP_ROUTES.VIEW_USER]: [
    { title: Strings[APP_ROUTES.LIST_USERS], url: APP_ROUTES.LIST_USERS },
    { title: Strings[APP_ROUTES.VIEW_USER] },
  ],

  [APP_ROUTES.ADD_COMPANY]: [
    {
      title: Strings[APP_ROUTES.LIST_COMPANIES],
      url: APP_ROUTES.LIST_COMPANIES,
    },
    { title: Strings[APP_ROUTES.ADD_COMPANY] },
  ],
};

//Links to be shown in manual breadcrumbs.
export const manualBreadcrumbData = {
  [APP_ROUTES.EDIT_COMPANY]: [
    {
      title: Strings[APP_ROUTES.LIST_COMPANIES],
      url: APP_ROUTES.LIST_COMPANIES,
    },
    { title: Strings[APP_ROUTES.EDIT_COMPANY] },
  ],
  [APP_ROUTES.VIEW_COMPANY]: [
    {
      title: Strings[APP_ROUTES.LIST_COMPANIES],
      url: APP_ROUTES.LIST_COMPANIES,
    },
    { title: Strings[APP_ROUTES.VIEW_COMPANY] },
  ],
  [APP_ROUTES.VIEW_COMPANY_ADMIN]: [
    {
      title: Strings[APP_ROUTES.LIST_COMPANY_ADMINS],
      url: APP_ROUTES.LIST_COMPANY_ADMINS,
    },
    { title: Strings[APP_ROUTES.VIEW_COMPANY_ADMIN] },
  ],
};

//Breadcrumb mapping for custom breadcrumbs that are specific to a page.
//Do not forget to bind "this" to the function.
export const manualBreadcrumbMapping = function (currentRoute) {
  let data = Object.create(manualBreadcrumbData[currentRoute]);

  //Do not show the breadcrumb links to company admin.
  if (userIsCompanyAdmin.apply(this)) {
    data = null;
  }

  return data;
};
