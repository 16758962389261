import {
  SET_FORM_DIRTINESS,
  RESET_FORM_DIRTINESS,
  CHECK_SHOW_MODAL,
  RESET_SHOW_MODAL,
  RESET_CANCEL_CONFIRM,
} from "../../constants/action-constants";

export const setFormDirtiness = () => {
  return {
    type: SET_FORM_DIRTINESS,
  };
};

export const resetFormDirtiness = () => {
  return {
    type: RESET_FORM_DIRTINESS,
  };
};

export const checkShowModal = (method) => {
  return {
    type: CHECK_SHOW_MODAL,
    payload: method,
  };
};

export const resetShowModal = () => {
  return {
    type: RESET_SHOW_MODAL,
  };
};

export const resetCancelConfirm = () => ({
  type: RESET_CANCEL_CONFIRM,
});
