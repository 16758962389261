import { PUT_STATES_LIST } from "../../constants/action-constants";
import { getAccessToken } from "../../services/browserStorage";
import { webApiCheck } from "../../services/commonMethods";
import { postDashboardWebApi } from "../../webApis/apiResource";

export const requestStatesList = (urlEndPoint, companyId) => {
  return async (dispatch) => {
    const response = await postDashboardWebApi(getAccessToken(), urlEndPoint, {
      companyId,
    });
    if (webApiCheck(response)) {
      dispatch(putStatesList(response.data.data));
    }
  };
};

export const resetStatesList = () => {
  return async (dispatch) => {
    dispatch(putStatesList([]));
  };
};

const putStatesList = (data) => {
  return {
    type: PUT_STATES_LIST,
    payload: data,
  };
};
