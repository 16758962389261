import { FETCH_USER, RESET_USER } from "../../constants/action-constants";

const initialState = {
  data: {},
};

const viewUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return { data: action.payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default viewUserReducer;
