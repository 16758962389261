import { TableCell } from "@material-ui/core";
import React from "react";

export default function RedirectToView(props) {
  const { redirectToView, children } = props;

  return (
    <TableCell>
      <span className="redirect-to-view" onClick={redirectToView}>
        {children}
      </span>
    </TableCell>
  );
}
