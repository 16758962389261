import React, { Component } from "react";
import { connect } from "react-redux";
import { requestCompaniesList } from "../../../modules/actions/companyList.action";
import { TableCell } from "@material-ui/core";
import { PERMISSIONS } from "../../../constants";
import DateColumn from "../../components/DateColumn";
import RedirectToView from "../../components/RedirectToView";
import CustomTable from "../../components/Table";
import Container from "../../components/Container";
import ActionColumn from "../../components/ActionColumn";
import Delete from "../../components/Modals/Delete";
import {
  checkUserHasPermission,
  userIsCompanyAdmin,
  USER_ROLE_IDS,
} from "../../../services/commonMethods";
import { requestIntegrationAdminsList } from "../../../modules/actions/userList.action";
import { errorToast } from "../../../modules/actions/toast.action";

//Component list all the companies in a table & provides Add/Edit/Delete Company button.
class CompaniesList extends Component {
  state = {
    tableData: [],
    showDeleteModal: false,
    currentInstance: this,
  };

  redirectCompanyToView = ({ companyId }) => {
    this.props.history.push(`companies/${companyId}`);
  };

  redirectUserToView = ({ companyId }) => {
    this.props.history.push(`company-admin/${companyId}`);
  };

  componentDidMount() {
    //Get latest list of companies
    this.props.requestCompaniesList("/api/company").then(() => {
      if (userIsCompanyAdmin.call(this)) {
        return this.props.history.push(
          `/companies/${this.props.companiesList[0].companyId}`
        );
      }
    });
    //get Integration admins list.
    this.props.requestIntegrationAdminsList("/api/user/get/all?roleId=3");
  }

  static getDerivedStateFromProps(nextProps, state) {
    let temp = [];
    let { currentInstance } = state;

    nextProps.companiesList.forEach((company) => {
      let json = {
        name: (
          <RedirectToView
            redirectToView={() =>
              currentInstance.redirectCompanyToView(company)
            }
          >
            <div className="text-ellipsis">{company.name}</div>
          </RedirectToView>
        ),
        country: <TableCell>{company.operationalCountry}</TableCell>,
        companyAdmin: (
          <RedirectToView
            redirectToView={() => currentInstance.redirectUserToView(company)}
          >
            <div className="text-ellipsis">{company.companyAdminName}</div>
          </RedirectToView>
        ),

        lastUpdatedOn: <DateColumn data={company} field="lastUpdatedOn" />,
        //Check if user has permission to edit/delete
        action: checkUserHasPermission(nextProps.me.role, [
          PERMISSIONS.UPDATE_AND_READ_COMPANY,
          PERMISSIONS.UPDATE_MY_COMPANY,
          PERMISSIONS.COMPANY_ALL_OPS,
        ]) ? (
          <ActionColumn
            editMethod={() =>
              nextProps.history.push(
                `/companies/edit-company/${company.companyId}`
              )
            }
            deleteMethod={
              nextProps.me.role.id === USER_ROLE_IDS["company-admin"] ||
              nextProps.me.role.id === USER_ROLE_IDS["integration-admin"]
                ? false
                : () => currentInstance.onDeleteAction(company)
            }
          />
        ) : null,
      };
      temp.push(json);
    });
    //Update state.
    return { tableData: temp };
  }

  tableHead = () => {
    let arr = [
      {
        title: "Name",
        props: {},
      },
      {
        title: "Country",
        props: {},
      },
      {
        title: "Company Admin",
        props: {},
      },
      {
        title: "Last Updated",
        props: {},
      },
      {
        title: "Action",
        props: {},
      },
    ];

    if (
      !checkUserHasPermission(this.props.me.role, [
        PERMISSIONS.UPDATE_AND_READ_COMPANY,
        PERMISSIONS.UPDATE_MY_COMPANY,
        PERMISSIONS.COMPANY_ALL_OPS,
      ])
    ) {
      //If user doesn't have permission to Modify companies, then delete Action tab.
      arr.pop();
    }
    return arr;
  };

  onDeleteAction = (obj) => {
    this.setState({
      deleteObj: obj,
      showDeleteModal: true,
    });
  };

  onDeleteSuccess = () => {
    this.setState({
      showDeleteModal: false,
      deleteObj: {},
    });

    //Re-render the companies list.
    this.props.requestCompaniesList("/api/company");
    this.props.history.push("/companies");
  };

  redirectUserToAddCompany = () => {
    //If there are no integration admin, show a toast & do not redirect the user.
    if (!this.props.integrationAdminsList.length) {
      return this.props.errorToast({
        data: {
          message:
            "No integration admins exist in the system, cannot add company without an integration admin",
        },
      });
    }
    //Else redirect the user to add company page.
    this.props.history.push("/companies/add-company");
  };

  render() {
    //Do not show companies list to company admin.
    if (userIsCompanyAdmin.call(this)) {
      return <></>;
    }

    return (
      <div>
        <Container
          title={"Companies List"}
          showBtns={checkUserHasPermission(this.props.me.role, [
            PERMISSIONS.COMPANY_ALL_OPS,
          ])}
          btnText={"Add New Company"}
          btnMethod={this.redirectUserToAddCompany}
        >
          <CustomTable
            tableHead={this.tableHead()}
            tableData={this.state.tableData}
            refr="company-table"
          />
        </Container>
        {this.state.showDeleteModal ? (
          <Delete
            open={this.state.showDeleteModal}
            onClose={() =>
              this.setState({
                showDeleteModal: false,
                deleteObj: {},
              })
            }
            name={this.state.deleteObj.name}
            urlEndPoint={`/api/company/${this.state.deleteObj.companyId}`}
            onSuccess={this.onDeleteSuccess}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesList: state.companyListReducer.list,
  me: state.meReducer.data,
  integrationAdminsList: state.userListReducer.integrationAdminsList,
});

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  requestIntegrationAdminsList: (urlEndPoint) =>
    dispatch(requestIntegrationAdminsList(urlEndPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
