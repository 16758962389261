import DashboardIcon from "../assets/icons/dashboard.svg";
// User Management
import UserList from "../pages/containers/UserManagement-CRUD/UserList";
import UserMngIcon from "../assets/icons/user_management.svg";
import ViewUser from "../pages/containers/UserManagement-CRUD/ViewUser";
import RightArrow from "../assets/icons/right-arrow.svg";
import RightArrowActive from "../assets/icons/right_arrow_active.svg";
import BuildingIcon from "../assets/icons/building.svg";
// permissions
import { PERMISSIONS } from "../constants";
import CompaniesList from "../pages/containers/CompanyManagement/CompaniesList";
import AddCompany from "../pages/containers/CompanyManagement/AddCompany";
import ViewCompany from "../pages/containers/CompanyManagement/ViewCompany";
import EditCompany from "../pages/containers/CompanyManagement/EditCompany";
import CompanyAdminsList from "../pages/containers/CompanyManagement/CompanyAdmins/CompanyAdminsList";
import ViewCompanyAdmin from "../pages/containers/CompanyManagement/CompanyAdmins/ViewCompanyAdmin";
import LicensesList from "../pages/containers/LicenseManagement/LicensesList";

const ROUTES = [
  {
    name: "Company Management",
    route: "/companies",
    hasSubRoutes: true,
    subRoutes: [],
    component: CompaniesList,
    sidebar: true,
    icon: BuildingIcon,
    permissions: [
      PERMISSIONS.READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.READ_MY_COMPANY,
    ],
  },
  {
    name: "Add company",
    route: "/companies/add-company",
    component: AddCompany,
    subSidebar: false,
    icon: DashboardIcon,
    permissions: [PERMISSIONS.COMPANY_ALL_OPS],
  },
  {
    name: "Company Management",
    route: "/companies/:id",
    component: ViewCompany,
    subSidebar: false,
    permissions: [
      PERMISSIONS.READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.READ_MY_COMPANY,
    ],
  },
  {
    name: "Edit Company",
    route: "/companies/edit-company/:id",
    component: EditCompany,
    subSidebar: false,
    icon: DashboardIcon,
    permissions: [
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.UPDATE_MY_COMPANY,
    ],
  },
  {
    name: "Admin Management",
    route: "/company-admins",
    component: CompanyAdminsList,
    subSidebar: true,
    icon: RightArrow,
    activeIcon: RightArrowActive,
    permissions: [
      PERMISSIONS.READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.READ_MY_COMPANY,
    ],
  },
  {
    name: "Admin Management",
    route: "/company-admin/:id",
    component: ViewCompanyAdmin,
    sidebar: false,
    permissions: [
      PERMISSIONS.READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.READ_MY_COMPANY,
    ],
  },
  {
    name: "License Management",
    route: "/licenses",
    component: LicensesList,
    subSidebar: true,
    icon: RightArrow,
    activeIcon: RightArrowActive,
    permissions: [
      PERMISSIONS.READ_COMPANY,
      PERMISSIONS.COMPANY_ALL_OPS,
      PERMISSIONS.UPDATE_AND_READ_COMPANY,
      PERMISSIONS.READ_LICENSE,
      PERMISSIONS.READ_COMPANY_LICENSE,
    ],
  },
  //
  {
    name: "User Management",
    route: "/users",
    component: UserList,
    sidebar: true,
    icon: UserMngIcon,
    hasSubRoutes: true,
    subRoutes: [],
    permissions: [
      PERMISSIONS.READ_INT_PARTNER,
      PERMISSIONS.READ_INT_ADMIN,
      PERMISSIONS.READ_SUPPORT_ADMIN,
      PERMISSIONS.INT_ADMINS_ALL_OPS,
      PERMISSIONS.INT_PARTNER_ALL_OPS,
      PERMISSIONS.SUPPORT_ADMIN_ALL_OPS,
    ],
  },
  {
    name: "User Management",
    route: "/user/:id",
    component: ViewUser,
    sidebar: false,
    permissions: [
      PERMISSIONS.READ_INT_PARTNER,
      PERMISSIONS.READ_INT_ADMIN,
      PERMISSIONS.READ_SUPPORT_ADMIN,
      PERMISSIONS.INT_ADMINS_ALL_OPS,
      PERMISSIONS.INT_PARTNER_ALL_OPS,
      PERMISSIONS.SUPPORT_ADMIN_ALL_OPS,
    ],
  },
  {
    name: "Integration Partner",
    route: "/integration-partners",
    component: UserList,
    subSidebar: true,
    icon: RightArrow,
    activeIcon: RightArrowActive,
    permissions: [
      PERMISSIONS.READ_INT_PARTNER,
      PERMISSIONS.INT_PARTNER_ALL_OPS,
    ],
  },
  {
    name: "Integration Partner",
    route: "/integration-partner/:id",
    component: ViewUser,
    subSidebar: false,
    permissions: [
      PERMISSIONS.READ_INT_PARTNER,
      PERMISSIONS,
      PERMISSIONS.INT_PARTNER_ALL_OPS,
    ],
  },
  {
    name: "Integration Admin",
    route: "/integration-admins",
    component: UserList,
    subSidebar: true,
    icon: RightArrow,
    activeIcon: RightArrowActive,
    permissions: [PERMISSIONS.READ_INT_ADMIN, PERMISSIONS.INT_ADMINS_ALL_OPS],
  },
  {
    name: "Integration Admin",
    route: "/integration-admin/:id",
    component: ViewUser,
    subSidebar: false,
    permissions: [PERMISSIONS.READ_INT_ADMIN, PERMISSIONS.INT_ADMINS_ALL_OPS],
  },
  {
    name: "Support Admins",
    route: "/support-admins",
    component: UserList,
    subSidebar: true,
    icon: RightArrow,
    activeIcon: RightArrowActive,
    permissions: [
      PERMISSIONS.READ_SUPPORT_ADMIN,
      PERMISSIONS.SUPPORT_ADMIN_ALL_OPS,
    ],
  },
  {
    name: "Support Admins",
    route: "/support-admin/:id",
    component: ViewUser,
    subSidebar: false,
    permissions: [
      PERMISSIONS.READ_SUPPORT_ADMIN,
      PERMISSIONS.SUPPORT_ADMIN_ALL_OPS,
    ],
  },
  //
];

ROUTES[0]["subRoutes"] = [ROUTES[4], ROUTES[6]];
ROUTES[7]["subRoutes"] = [ROUTES[9], ROUTES[11], ROUTES[13]];

export default ROUTES;
