import { FETCH_ROLES } from "../../constants/action-constants";

const initialState = {
  list: [],
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      return { list: action.payload };
    default:
      return state;
  }
};

export default rolesReducer;
