import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

export default function CustomSwitch(props) {
  const { inputProps, labelText } = props;

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            {...inputProps}
            classes={{
              root: "custom-switch-root",
              track: "custom-switch-track",
              thumb: "custom-switch-thumb",
              switchBase: "custom-switch-base",
            }}
          />
        }
        label={labelText}
        labelPlacement="start"
        classes={{
          root: "switch-form-control-label",
        }}
      />
    </div>
  );
}
