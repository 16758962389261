import React, { Component } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "./scss/main.scss";
import GateKeeper from "./gatekeeper";
import configureStore from "./modules/store";
import "./services/prototypes";

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

class App extends Component {
  render() {
    return (
      <ReduxProvider store={reduxStore}>
        <CookiesProvider>
          <div className="App">
            <GateKeeper />
          </div>
        </CookiesProvider>
      </ReduxProvider>
    );
  }
}

export default App;
