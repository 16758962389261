// login.action
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOG_OUT = "LOG_OUT";

// toast.action
export const SUCCESS_TOAST = "SUCCESS_TOAST";
export const ERROR_TOAST = "ERROR_TOAST";
export const RESET_ERROR_TOAST = "RESET_ERROR_TOAST";
export const RESET_SUCCESS_TOAST = "RESET_SUCCESS_TOAST";

// userList.action
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const PUT_INTEGRATION_ADMIN_LIST = "PUT_INTEGRATION_ADMIN_LIST";
export const PUT_SUPPORT_ADMIN_LIST = "PUT_SUPPORT_ADMIN_LIST";

// viewUser.action
export const FETCH_USER = "FETCH_USER";
export const RESET_USER = "RESET_USER";

// roles.action
export const FETCH_ROLES = "FETCH_ROLES";

// me.action
export const FETCH_ME = "FETCH_ME";

// services.action
export const PUT_SERVICES_LIST = "PUT_SERVICES_LIST";

//company.action
export const PUT_COMPANIES_LIST = "PUT_COMPANIES_LIST";
export const PUT_CURRENT_COMPANY_DATA = "PUT_CURRENT_COMPANY_DATA";

//countries.action
export const PUT_COUNTRIES_LIST = "PUT_COUNTRIES_LIST";

//paymentGateways.action
export const PUT_PAYMENT_GATEWAYS_LIST = "PUT_PAYMENT_GATEWAYS_LIST";

//paymentMethods.action
export const PUT_PAYMENT_METHODS_LIST = "PUT_PAYMENT_METHODS_LIST";

//vendingMachineBoard.action
export const PUT_VENDING_MACHINE_BOARD_LIST = "PUT_VENDING_MACHINE_BOARD_LIST";

//cities.action
export const PUT_CITIES_LIST = "PUT_CITIES_LIST";

//states.action
export const PUT_STATES_LIST = "PUT_STATES_LIST";

//viewCompany.action
export const FETCH_COMPANY = "FETCH_COMPANY";
export const RESET_COMPANY = "RESET_COMPANY";

// cancelConfirm.action
export const SET_FORM_DIRTINESS = "SET_FORM_DIRTINESS";
export const RESET_FORM_DIRTINESS = "RESET_FORM_DIRTINESS";
export const CHECK_SHOW_MODAL = "CHECK_SHOW_MODAL";
export const RESET_SHOW_MODAL = "RESET_SHOW_MODAL";
export const RESET_CANCEL_CONFIRM = "RESET_CANCEL_CONFIRM";
