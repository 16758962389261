import React, { Component } from "react";
import { connect } from "react-redux";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import { requestRoles } from "../../../modules/actions/roles.action";
import { withRouter } from "react-router-dom";
import CustomInput from "../../components/Input";
import CustomSwitch from "../../components/Switch";
import SaveIcon from "../../../assets/icons/save.svg";
import FormValidator from "../../../services/FormValidator";
import { getAccessToken } from "../../../services/browserStorage";
import {
  errorToast,
  successToast,
} from "../../../modules/actions/toast.action";
import {
  postDashboardWebApi,
  putDashboardWebApi,
} from "../../../webApis/apiResource";
import {
  requestUser,
  resetUser,
} from "../../../modules/actions/viewUser.action";
import {
  objectHasKeysCheck,
  webApiCheck,
  getUserConfig,
  enableFormDirtiness,
} from "../../../services/commonMethods";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
} from "@material-ui/core";
import {
  checkShowModal,
  setFormDirtiness,
  resetFormDirtiness,
} from "../../../modules/actions/cancelConfirm.action";

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.userConfig = getUserConfig(this.props);

    this.state = {
      show: false,
      name: null,
      email: "",
      contactNumber: "",
      roleId: this.userConfig.roleId,
      enabled: false,
      version: null,
      roles: [],
    };

    this.rules = {
      name: { required: true },
      email: { required: true, email: true },
      contactNumber: { required: true, isMobile: true, exactlength: 10 },
      roleId: { required: true },
    };
  }

  submit = async () => {
    const data = {
      name: this.state.name,
      contactNumber: this.state.contactNumber,
      email: this.state.email,
      roleId: this.state.roleId,
      enabled: this.state.enabled,
    };

    let urlEndPoint = `/api/user/create/${this.getCreateUrl(
      this.state.roleId
    )}`;

    if (this.props.userId) {
      urlEndPoint = `/api/user/${this.props.userId}`;
      data["version"] = this.state.version;
    }

    const response = this.props.userId
      ? await putDashboardWebApi(getAccessToken(), urlEndPoint, data)
      : await postDashboardWebApi(getAccessToken(), urlEndPoint, data);
    if (webApiCheck(response)) {
      this.props.successToast({
        message: `${this.userConfig.name} ${
          this.props.userId ? "updated" : "created"
        } successfully!`,
      });
      this.props.onSuccess();
    }
  };

  btns = [
    {
      type: "submit",
      btnProps: {
        text: [
          <img src={SaveIcon} alt="save" key={0} />,
          this.props.userId ? "Update" : "Save",
        ],
        className: "save-btn float-right",
        variant: "contained",
        method: this.submit,
      },
    },
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Cancel"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: this.props.onClose,
      },
    },
  ];

  static getDerivedStateFromProps(nextProps, nextState) {
    const getRoles = (role, roles) => {
      if (role && (parseInt(role.id) === 2 || parseInt(role.id) === 1)) {
        return roles;
      } else {
        return roles.filter((rl) => parseInt(rl.id) === 4);
      }
    };

    if (
      nextProps.open &&
      nextState.name === null &&
      !objectHasKeysCheck(nextProps.user) &&
      nextProps.roles.length &&
      nextState.roles.length === 0
    ) {
      return {
        show: nextProps.open,
        roles: getRoles(nextProps.me.role, nextProps.roles),
      };
    } else if (
      objectHasKeysCheck(nextProps.user) &&
      nextProps.open &&
      nextState.name === null &&
      nextProps.roles.length
    ) {
      return {
        name: nextProps.user.name,
        email: nextProps.user.email,
        contactNumber: nextProps.user.contactNumber,
        roleId: nextProps.user.role.id,
        enabled: nextProps.user.enabled,
        show: nextProps.open,
        version: nextProps.user.version,
        roles: getRoles(nextProps.me.role, nextProps.roles),
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.props.requestRoles("/api/role");
    if (!!this.props.userId) {
      this.props.requestUser(`/api/user/${this.props.userId}`);
    }
  }

  componentWillUnmount() {
    this.props.resetFormDirtiness();
    //Reset only if adding an user on users list, not when editing an user.
    if (
      [
        "/users",
        "/integration-partners",
        "/integration-admins",
        "/support-admins",
      ].includes(this.props.location.pathname)
    ) {
      this.props.resetUser();
    }
  }

  handleChange = (ev) => {
    return this.setState(
      {
        [ev.target.name]: ev.target.value,
      },
      enableFormDirtiness.bind(this, this.props.onClose)
    );
  };

  getCreateUrl = (roleId) => {
    let id = parseInt(roleId);
    switch (id) {
      case 2:
        return "integrationPartner";
      case 3:
        return "integrationAdmin";
      case 4:
        return "supportAdmin";
      default:
        return;
    }
  };

  render() {
    return (
      <Dialog
        classes={{ paper: "add-user-modal" }}
        open={this.state.show}
        onClose={this.props.onClose}
      >
        <DialogTitle className="dialog-title">
          <span>
            {this.props.userId ? "Edit" : "Add New"} {this.userConfig.name}
          </span>
          <img src={CrossWhiteIcon} alt="cross" onClick={this.props.onClose} />
        </DialogTitle>
        <FormValidator
          rules={this.rules}
          data={this.state}
          btns={this.btns}
          errorToast={this.props.errorToast}
        >
          <DialogContent className="dialog-content">
            <Grid container>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: this.state.name,
                    onChange: this.handleChange,
                    required: true,
                    label: "Name",
                    name: "name",
                    type: "text",
                    InputLabelProps: {
                      shrink: this.state.name,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                  }}
                  inputProps={{
                    value: this.state.email,
                    onChange: !!this.props.userId
                      ? () => {}
                      : this.handleChange,
                    required: true,
                    disabled: !!this.props.userId,
                    label: "Email",
                    name: "email",
                    type: "email",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: this.state.contactNumber,
                    onChange: this.handleChange,
                    required: true,
                    label: "Mobile",
                    name: "contactNumber",
                    type: "number",
                    className: "mobile-field",
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: {
                      width: "calc(100% - 24px)",
                    },
                  }}
                  inputProps={{
                    value: this.state.roleId,
                    onChange: this.handleChange,
                    required: true,
                    label: "Type",
                    name: "roleId",
                    select: true,
                    disabled: this.userConfig.disableRoleField,
                  }}
                >
                  {this.state.roles.map((role) => (
                    <MenuItem value={role.id} key={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Grid>
              <Grid item lg={6}>
                <CustomSwitch
                  inputProps={{
                    value: this.state.enabled,
                    checked: this.state.enabled,
                    onChange: (ev) =>
                      this.setState({ enabled: ev.target.checked }),
                    name: "enabled",
                  }}
                  labelText="Status"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
        </FormValidator>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.rolesReducer.list,
  user: state.viewUserReducer.data,
  me: state.meReducer.data,
  formDirtBool: state.cancelConfirmReducer.formDirtBool,
});

const mapDispatchToProps = (dispatch) => ({
  requestRoles: (urlEndPoint) => dispatch(requestRoles(urlEndPoint)),
  successToast: (obj) => dispatch(successToast(obj)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  requestUser: (urlEndPoint) => dispatch(requestUser(urlEndPoint)),
  resetUser: () => dispatch(resetUser()),
  checkShowModal: (method) => dispatch(checkShowModal(method)),
  setFormDirtiness: () => dispatch(setFormDirtiness()),
  resetFormDirtiness: () => dispatch(resetFormDirtiness()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddUser));
