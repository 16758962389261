import { FETCH_COMPANY, RESET_COMPANY } from "../../constants/action-constants";

const initialState = {
  companyId: null,
  name: "",
  url: "",
  country: "",
  validFrom: "",
  validTo: "",
  primaryIntegrationAdminId: null,
  companyAdminId: null,
  address: null,
  accountDetail: null,
  integrationAdmins: [],
  licenseVO: null,
  createdOn: "",
  lastUpdatedOn: "",
  supportAdmin: null,
  showDeleteModal: false,
  paymentGateways: [],
  boards: [],
};

const viewCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY:
      return { ...action.payload };
    case RESET_COMPANY:
      return initialState;
    default:
      return state;
  }
};

export default viewCompanyReducer;
